import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FormEvent, useCallback, useState } from 'react';
import { gs } from '../../../../../utils/clients/gs';

export const MessageBox = () => {
  const [text, setText] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setSubmitting(true);
      gs.post('/chat', {
        text,
      })
        .then(() => {
          setText('');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [text],
  );
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex flex-row justify-between items-center gap-2"
    >
      <InputText
        type="text"
        placeholder="Write something"
        value={text}
        onChange={e => setText(e.target.value)}
        className="flex-grow p-inputtext-sm"
      />
      <Button
        type="submit"
        icon="pi pi-send"
        disabled={submitting}
        size="small"
      />
    </form>
  );
};
