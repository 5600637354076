import { FC, useCallback, useMemo, useState } from 'react';
import { WORLD_LAYOUT, hexToScreen } from '@chiefdom/shared';
import { Dropdown } from 'primereact/dropdown';
import { Vector3 } from 'three';
import { useGameStore } from '../../../store/game-store';
import { cameraPositionRequests } from '../../../../events/camera-events';
import { NONE } from '../../../../constants/general';

export const HoldingSelector: FC = () => {
  const holdings = useGameStore(state => state.player?.realm.holdings || []);
  const options = useMemo(
    () =>
      holdings
        .filter(item => item.type !== NONE)
        .map(item => ({ label: item.name, value: item.id })),
    [holdings],
  );

  const [selected, setSelected] = useState(options?.[0]?.value);

  const handleSelection = useCallback(
    (event: any) => {
      setSelected(event.value);
      const holding = holdings.find(item => item.id === event.value);
      if (holding) {
        const { x, y } = hexToScreen(WORLD_LAYOUT, holding);
        cameraPositionRequests.push(new Vector3(x, 0, y));
      }
    },
    [holdings],
  );

  return (
    <div className="absolute right-2 top-8 interactive">
      <Dropdown value={selected} options={options} onChange={handleSelection} />
    </div>
  );
};
