import { HoldingSelector } from './HoldingSelector';
import { PlayerCharacterNameRenderer } from './PlayerRenderer';
import { ResourceListUI } from './ResourceListUI';

export const PlayerDockUI = () => {
  return (
    <>
      <ResourceListUI />
      <PlayerCharacterNameRenderer />
      <HoldingSelector />
    </>
  );
};
