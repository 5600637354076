import { FC } from 'react';
import { useGetPlayerResources } from '../../../store/game-store';
import { ReactComponent as Food } from '../../../../assets/icons/food.svg';
import { ReactComponent as Wood } from '../../../../assets/icons/wood.svg';
import { ReactComponent as Stone } from '../../../../assets/icons/stone.svg';
import { ReactComponent as Iron } from '../../../../assets/icons/iron.svg';
import { ReactComponent as Coin } from '../../../../assets/icons/coin.svg';
import { ReactComponent as Population } from '../../../../assets/icons/population.svg';
import { formatNumber } from '../../../../utils/numbers';
import clsx from 'clsx';

export const ResourceListUI: FC = () => {
  const resource = useGetPlayerResources();
  return (
    <div className="absolute top-0 right-0 left-0 flex flex-row justify-between items-center md:left-auto">
      <div className="px-4 py-2 flex flex-row justify-between items-center gap-4 interactive w-full bg-opacity-50 bg-gray-700 md:shadow-gray-700/10 md:shadow-lg md:justify-end md:left-auto md:flex-grow-0 md:rounded-bl-3xl">
        <RenderResource type="food" value={resource.food} />
        <RenderResource type="wood" value={resource.wood} />
        <RenderResource type="stone" value={resource.stone} hideOnMobile />
        <RenderResource type="iron" value={resource.iron} hideOnMobile />
        <RenderResource type="gold" value={resource.gold} />
        <RenderResource type="population" value={resource.population} />
        {/* <RenderResource type="diamonds?¿" value={resource.wood} /> */}
      </div>
    </div>
  );
};

type RenderResourceProps = {
  type: string;
  value?: number;
  hideOnMobile?: boolean;
};

export const RenderResource: FC<RenderResourceProps> = ({
  type,
  value,
  hideOnMobile,
}) => (
  <div
    className={clsx(
      'flex flex-row justify-start items-center bg-black bg-opacity-50 rounded-md hover:scale-125 transition-transform duration-300',
      hideOnMobile && 'hidden sm:flex',
    )}
  >
    <div className="w-4">{resourceIcon(type)}</div>
    <div className="px-2 text-xs text-white">{formatNumber(value)}</div>
  </div>
);

const resourceIcon = (type: string) => {
  switch (type) {
    case 'food':
      return <Food className="scale-150 text-orange-500" />;
    case 'wood':
      return <Wood className="scale-150 text-orange-300" />;
    case 'stone':
      return <Stone className="scale-150 text-orange-200" />;
    case 'iron':
      return <Iron className="scale-150 text-blue-300" />;
    case 'gold':
      return <Coin className="scale-150 text-orange-400" />;
    case 'population':
      return <Population className="scale-150 text-white" />;
    default:
      return null;
  }
};
