import { FC } from 'react';

import { WATER_WORLD } from '../../../../config/water-config';
import { T3RealmList } from '../../../components/2_molecules/T3RealmList/T3RealmList';
import { useHexStore } from '../../../store/game/hex-store';
import { PlayerSeeker } from './1_atoms/PlayerSeeker';
import InstancedGrass from './3_organism/InstancedGrass';
import InstancedRocks from './3_organism/InstancedRocks';
import InstancedRocksSand from './3_organism/InstancedRocksSand';
import InstancedTiles from './3_organism/InstancedTiles';
import InstancedTrees from './3_organism/InstancedTrees';
import Water from './3_organism/Water';
import { useSettingsStore } from '../../../store/settings/settings-store';

type WorldTerrainProps = {};

export const WorldTerrain: FC<WorldTerrainProps> = () => {
  const selectedHex = useHexStore(state => state.selected);
  const selectHex = useHexStore(state => state.selectHex);
  const hide = useSettingsStore(state => state.disableMapAssets);

  return (
    <group>
      {!hide.trees && <InstancedTrees />}
      {!hide.grass && <InstancedGrass />}
      {!hide.rocks && <InstancedRocks />}
      {!hide.rocks && <InstancedRocksSand />}
      <InstancedTiles selectedHex={selectedHex} setSelectedHex={selectHex} />
      <Water settings={WATER_WORLD} />
      <T3RealmList />
      <PlayerSeeker />
    </group>
  );
};
