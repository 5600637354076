import { useMemo } from 'react';

import { useHoldingStore } from '../../../../store/game/holding-store';
import { useMapStore } from '../../../../store/game/map-store';
import { BottomDock } from '../../../2_molecules/BottomDock/BottomDock';

export const SelectedHolding = () => {
  const selected = useMapStore(state => state.selected);
  const select = useMapStore(state => state.selectHolding);
  const enter = useHoldingStore(state => state.setSelectedHolding);

  const items = useMemo(
    () => [
      {
        label: 'Enter',
        icon: 'pi pi-arrow-right',
        onClick: () => enter(selected!),
      },
    ],
    [enter, selected],
  );

  return (
    <BottomDock
      model={items}
      visible={!!selected}
      onHide={() => select(null)}
    />
  );
};
