import { FC, useCallback, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';

import { buildingEvents } from '../../../../events/buildingEvents';
import {
  Cost,
  Structure,
  structures,
  StructureType,
} from '@chiefdom/collections';
import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import { CostRenderer } from '../../../components/1_atoms/CostRenderer/CostRenderer';
import { useGetPlayerResources } from '../../../store/game-store';
import { useVerifyCost } from '../../../hooks/useVerifyCost';

type BuildingListProps = {
  visible: boolean;
  onHide: () => void;
};

const buildings = structures.filter(
  item => !item.isHolding && item.level === 1,
  // todo: actually filter by current holding
  // item.requiredHoldings.includes(StructureHoldingType.city),
);

export const BuildingList: FC<BuildingListProps> = ({ visible, onHide }) => {
  const resources = useGetPlayerResources();
  const handleBuilding = useCallback(
    (building: StructureType) => {
      buildingEvents.push(building);
      onHide();
    },
    [onHide],
  );
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      showHeader
      className="w-1/2"
      header="New construction"
    >
      <div>
        {buildings.map(building => (
          <BuildingPreview
            key={building.id}
            structure={building}
            resources={resources}
            onClick={handleBuilding}
          />
        ))}
      </div>
    </Dialog>
  );
};

const BuildingPreview: FC<{
  structure: Structure;
  resources: Partial<Cost>;
  onClick: (id: StructureType) => void;
}> = ({ structure, resources, onClick }) => {
  const { valid } = useVerifyCost(structure.cost, resources);

  const maybeRenderProduction = useMemo(() => {
    const production = Object.entries(structure.production);
    if (!production.length) return null;
    return (
      <div className="my-3">
        <h5 className="font-semibold">Production:</h5>
        <div className="flex flex-col">
          {production.map(([type, value]) => (
            <div key={type}>
              {type} {value}
            </div>
          ))}
        </div>
      </div>
    );
  }, [structure.production]);

  const renderBuildTime = useMemo(() => {
    if (!structure.time) return null;
    return dayjs.duration(structure.time, 'seconds').humanize();
  }, [structure.time]);

  const handleClick = useCallback(
    (structure: Structure) => (event: any) => {
      event.stopPropagation();
      onClick(structure.id);
    },
    [onClick],
  );

  return (
    <div className="mb-8">
      <h4 className="mb-3">{structure.name}</h4>
      <h5 className="font-semibold">Production:</h5>
      <CostRenderer cost={structure.cost} available={resources} />
      {maybeRenderProduction}
      <div className="my-3">
        <h5 className="font-semibold">Build time:</h5>
        <div>{renderBuildTime}</div>
      </div>
      <Button
        onClick={handleClick(structure)}
        className="mt-3"
        disabled={!valid}
      >
        Build
      </Button>
    </div>
  );
};
