import {
  InstancedMesh,
  Shape,
  ExtrudeGeometry,
  Color,
  Mesh,
  BufferGeometry,
  MeshPhongMaterial,
  ColorRepresentation,
} from 'three';
import {
  mergeBufferGeometries,
  mergeVertices,
} from 'three/examples/jsm/utils/BufferGeometryUtils';
import { IRealm } from '../../../../types/models';
import {
  WORLD_LAYOUT,
  hex,
  hexToScreen,
  polygonCorners,
} from '@chiefdom/shared';
import { getWorldMapTileHeightAtPoint } from '../../../../utils/map/hex';

const material = new MeshPhongMaterial({
  color: new Color(0x0000ff),
  opacity: 0.5,
  transparent: true,
  // wireframe: true,
});

export const makeInstancedMesh = (limit = 100) =>
  new InstancedMesh(createShape(), material, limit);

function createShape() {
  const shape = new Shape();

  const points = polygonCorners(WORLD_LAYOUT, hex(0, 0, 0));

  shape.moveTo(points[0].x, points[0].y);

  for (let i = 1; i < points.length; i++) {
    shape.lineTo(points[i].x, points[i].y);
  }

  return new ExtrudeGeometry(shape, {
    steps: 1,
    depth: 10,
    bevelEnabled: true,
    bevelThickness: 0,
    bevelSize: 0,
    bevelOffset: 0.01,
    bevelSegments: 0,
  });
}

export const createRealmMesh = (realm: IRealm) => {
  const { holdings } = realm;

  const hexagon = createShape();

  const shapes: BufferGeometry[] = [];
  for (const holding of holdings) {
    const shape = hexagon.clone();
    const height = getWorldMapTileHeightAtPoint(holding) + 5.1;
    const pos = hexToScreen(WORLD_LAYOUT, holding);
    shape.rotateX(-Math.PI / 2);
    shape.center();
    shape.translate(pos.x, height, pos.y);
    shapes.push(shape);
  }

  material.color = new Color(
    `#${realm.title.coa.primaryColor}` as ColorRepresentation,
  );

  if (shapes.length > 1) {
    const merged = mergeBufferGeometries(shapes, true);
    mergeVertices(merged);
    return new Mesh(merged, material);
  }

  if (shapes.length === 1) {
    return new Mesh(shapes[0], material);
  }

  throw new Error('No shapes to merge');
};
