import {
  HEX_CHUNK_OFFSETS,
  HEX_DIRECTIONS,
  HEX_ORIENTATION,
  hexAdd,
  hexScale,
  hexToScreen,
  layout,
  URBAN_LAYOUT,
} from '@chiefdom/shared';

import { FC, useEffect, useRef } from 'react';

import { FrontSide, InstancedMesh } from 'three';
import * as THREE from 'three';

import { TILE_COLORS } from '../../../../constants/map';
import { getColor } from '../../../../utils/generation/color';
import { getNoiseUrbanOutskirtByCoords } from '../../../../utils/generation/tile-coords';
import { HEX_URBAN_MAP_CHUNK, HexTile } from '../../../../utils/map/generator';
import BeveledHexagonGeometry from '../../WorldScene/components/1_atoms/BeveledHexagonGeometry';

const scratchObject3D = new THREE.Object3D();

type OutskirtTilesInstancedProps = {
  hexTile?: HexTile;
  direction: number;
};

export const OutskirtTilesInstanced: FC<OutskirtTilesInstancedProps> = ({
  hexTile,
  direction,
}) => {
  const meshRef = useRef<InstancedMesh>(null);
  const colorGetter = getColor();
  const scaledMainHex = hexScale(HEX_DIRECTIONS[direction], 21);

  let color = colorGetter(TILE_COLORS.Water.value);
  let waterPushDown: number = -0.8;
  if (hexTile !== undefined) {
    waterPushDown = hexTile.height;
    color = hexTile.color;
  }

  useEffect(() => {
    if (!meshRef.current) return;

    for (let i = 0; i < HEX_URBAN_MAP_CHUNK.length; i++) {
      const pushedHex = hexAdd(HEX_URBAN_MAP_CHUNK[i], scaledMainHex);
      const coords = hexToScreen(
        layout(HEX_ORIENTATION, 1, HEX_CHUNK_OFFSETS[direction]),
        pushedHex,
      );

      scratchObject3D.position.set(
        coords.x,
        getNoiseUrbanOutskirtByCoords(coords.x, coords.y) + waterPushDown,
        coords.y,
      );
      scratchObject3D.rotation.set(Math.PI / 2, 0, 0);
      scratchObject3D.updateMatrix();

      meshRef.current.setMatrixAt(i, scratchObject3D.matrix);
      meshRef.current.setColorAt(i, color);
    }
    meshRef.current.instanceMatrix.needsUpdate = true;
    if (meshRef.current.instanceColor) {
      meshRef.current.instanceColor.needsUpdate = true;
    }
  }, [scaledMainHex, hexTile, waterPushDown, direction, color]);

  return (
    <instancedMesh
      ref={meshRef}
      args={[undefined, undefined, HEX_URBAN_MAP_CHUNK.length]}
      frustumCulled={false}
    >
      <BeveledHexagonGeometry attach={'geometry'} layout={URBAN_LAYOUT} />
      <meshPhongMaterial
        attach={'material'}
        shadowSide={FrontSide}
        side={FrontSide}
      />
    </instancedMesh>
  );
};
