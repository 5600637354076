export const models = {
  // holdings
  holding_tribe: '/models/rts/Castle.glb',
  holding_castle: '/models/rts/Castle Fortress.glb',
  holding_city: '/models/rts/Houses.glb',
  holding_temple: '/models/rts/Temple.glb',
  // buildings
  building_barracks: '/models/rts/Barracks.glb',
  building_castle: '/models/rts/Castle Fortress.glb',
  building_crops: '/models/rts/Crops.glb',
  building_farm: '/models/rts/Farm.glb',
  building_fortress: '/models/rts/Fortress.glb',
  building_house: '/models/rts/House.glb',
  building_hut: '/models/rts/Hut.glb',
  building_shack: '/models/rts/Shack.glb',
  building_temple: '/models/rts/Temple.glb',
  building_windmill: '/models/rts/Windmill.glb',
  building_market: '/models/rts/Market Stalls.glb',
  // environment
  environment_mine: '/models/rts/Mine.glb',
  environment_trees_1: '/models/nature/tree_pineGroundB.glb',
  environment_trees_2: '/models/nature/tree_pineRoundE.glb',
  environment_gold: '/models/rts/Resource Gold.glb',
  environment_rock: '/models/nature/rock_smallFlatA.glb',
  environment_rocks: '/models/nature/rock_smallFlatA.glb',
  environment_rock_1: '/models/nature/rock_smallFlatA.glb',
  environment_rock_2: '/models/nature/rock_tallH.glb',
  environment_rock_3: '/models/nature/platform_stone.glb',
  environment_rock_4: '/models/nature/stone_tallB.glb',
  environment_mountain: '/models/rts/Mountain.glb',
  environment_mountains: '/models/rts/Mountains.glb',
  environment_logs: '/models/rts/Logs.glb',
  environment_trees_cut: '/models/rts/Trees cut.glb',
  environment_grass_1: '/models/nature/grass_leafsLarge.glb',
  environment_grass_2: '/models/nature/grass_large.glb',
  // blend by me
  tower: '/models/tower.glb',
} as const;
