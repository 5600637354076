import { Hex, IHex } from '@chiefdom/shared';
import { ThreeEvent } from '@react-three/fiber';

import { FC, useCallback, useLayoutEffect, useRef } from 'react';

import * as THREE from 'three';
import { FrontSide, InstancedMesh } from 'three';

import BeveledHexagonGeometry from '../1_atoms/BeveledHexagonGeometry';
import { worldMapHexes } from '../../../../../config/map/setup';
import { Nullable } from '../../../../../types';
import { areHexesEqual } from '../../../../../utils/helpers/hex';

const scratchObject3D = new THREE.Object3D();

type InstancedTilesProps = {
  selectedHex?: Nullable<IHex>;
  setSelectedHex: (hex: Nullable<IHex>) => void;
};

const InstancedTiles: FC<InstancedTilesProps> = ({
  selectedHex,
  setSelectedHex,
}) => {
  const meshRef = useRef<InstancedMesh>(null);

  const controlSelectedHex = useCallback(
    (e: ThreeEvent<MouseEvent>) => {
      e.stopPropagation();

      const hexData = worldMapHexes.find(item => item.id === e.instanceId);
      const v3 = hexData?.positionHex as THREE.Vector3;
      const hex = Hex.create({ q: v3.x, r: v3.y, s: v3.z });

      if (selectedHex && areHexesEqual(hex, selectedHex)) {
        setSelectedHex(null);
        return;
      } else {
        setSelectedHex(hex);
      }
    },
    [selectedHex, setSelectedHex],
  );

  useLayoutEffect(() => {
    if (!meshRef.current) return;
    if (worldMapHexes) {
      for (let i = 0; i < worldMapHexes.length; i++) {
        const hex = worldMapHexes[i];

        scratchObject3D.position.set(
          hex.positionHexScreen.x,
          hex.height,
          hex.positionHexScreen.y,
        );

        scratchObject3D.rotation.set(-Math.PI / 2, 0, 0);
        scratchObject3D.updateMatrix();

        meshRef.current.setMatrixAt(hex.id, scratchObject3D.matrix);
        meshRef.current.setColorAt(hex.id, hex.color);
      }
    }
    meshRef.current.instanceMatrix.needsUpdate = true;
    if (meshRef.current.instanceColor) {
      meshRef.current.instanceColor.needsUpdate = true;
    }
  }, []);

  return (
    <group>
      <instancedMesh
        ref={meshRef}
        args={[undefined, undefined, worldMapHexes.length]}
        frustumCulled={false}
        onClick={controlSelectedHex}
      >
        <BeveledHexagonGeometry attach={'geometry'} size={1} />
        <meshPhongMaterial
          attach={'material'}
          shadowSide={FrontSide}
          side={FrontSide}
        />
      </instancedMesh>
    </group>
  );
};

export default InstancedTiles;
