import { Cost } from '@chiefdom/collections';
import { useMemo } from 'react';

export const useVerifyCost = (
  cost: Partial<Cost>,
  resources: Partial<Cost> = {},
) => {
  const items = useMemo(
    () =>
      Object.keys(cost).map(key => {
        const costValue = (cost as any)[key];
        const resourceValue = (resources as any)[key];

        return {
          resource: key,
          required: costValue,
          available: resourceValue,
          valid: resourceValue >= costValue,
        };
      }),
    [cost, resources],
  );

  return {
    items,
    valid: items.every(item => item.valid),
  };
};
