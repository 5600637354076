import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Nullable } from '../../types';
import { ShardType } from '../../types/login-server';

type ShardStoreState = {
  loading: boolean;
  shard: Nullable<ShardType>;
  setLoading: (loading: boolean) => void;
  setShard: (shard: Nullable<ShardType>) => void;
};

export const useShardStore = create(
  persist(
    immer<ShardStoreState>(set => ({
      loading: true,
      shard: null,
      setLoading: loading =>
        set(draft => {
          draft.loading = loading;
        }),
      setShard: shard =>
        set(draft => {
          draft.shard = shard;
        }),
    })),
    {
      name: 'shard-store',
    },
  ),
);
