import { FC, PropsWithChildren, useEffect } from 'react';
import { connectToSockets } from '../../../../utils/clients/gs-socket';

export const SocketProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const socket = connectToSockets();

    return () => {
      socket.offAny();
      socket.disconnect();
    };
  }, []);

  return <>{children}</>;
};
