import { useEffect, useState } from 'react';
import { useHoldingStore } from '../../../store/game/holding-store';
import { IHolding } from '../../../../types/models';
import { gs } from '../../../../utils/clients/gs';

// todo: call only once
export const useSelectedHolding = () => {
  const holdingId = useHoldingStore(state => state.selectedHolding);
  const setLoading = useHoldingStore(state => state.setLoading);
  const getSelectedHolding = useHoldingStore(state => state.getSelectedHolding);
  const setHoldings = useHoldingStore(state => state.setHoldings);

  const [holding, setHolding] = useState<IHolding | undefined>(
    getSelectedHolding(),
  );

  useEffect(() => {
    setLoading(true);
    gs.get(`/holdings/${holdingId}`)
      .then(({ data }) => {
        setHoldings([data]);
        setHolding(getSelectedHolding());
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getSelectedHolding, holdingId, setHoldings, setLoading]);

  return holding;
};
