import {
  getIndexOfDirection,
  hex,
  hexNeighbors,
  hexSubtract,
} from '@chiefdom/shared';

import { FC } from 'react';

import { IHolding } from '../../../../types/models';
import { OutskirtTilesInstanced } from './OutskirtTilesInstanced';
import { getWorldMapTile } from '../../../../utils/map/hex';

type OutskirtGroupProps = {
  holding?: IHolding;
};

export const OutskirtGroup: FC<OutskirtGroupProps> = ({ holding }) => {
  if (!holding) return null;
  const hexWorld = hex(holding.q, holding.r, holding.s);
  const hexWorldNeighbors = hexNeighbors(hexWorld);
  return (
    <group rotation={[0, 0, 0]}>
      {hexWorldNeighbors.map((hexTile, i) => (
        <OutskirtTilesInstanced
          key={i}
          hexTile={getWorldMapTile(hexTile)}
          direction={getIndexOfDirection(hexSubtract(hexWorld, hexTile))}
        />
      ))}
    </group>
  );
};
