import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Nullable } from '../../../types';
import { IHex } from '@chiefdom/shared';

type HexStore = {
  selected: Nullable<IHex>;
  selectHex: (hex: Nullable<IHex>) => void;
};

export const useHexStore = create(
  immer<HexStore>(set => ({
    selected: null,
    selectHex: hex =>
      set(draft => {
        draft.selected = hex;
      }),
  })),
);
