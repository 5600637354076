import { useCallback } from 'react';
import { MenuType, useMenuStore } from '../../../store/menu-store';
import { SettingsDialog } from './components/SettingsDialog/SettingsDialog';

export const ModalProvider = () => {
  const tab = useMenuStore(state => state.item);
  const set = useMenuStore(state => state.set);

  const handleClose = useCallback(() => set(MenuType.None), [set]);

  return (
    <>
      <SettingsDialog
        visible={tab === MenuType.Settings}
        onClose={handleClose}
      />
    </>
  );
};
