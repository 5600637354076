import { Vector2 } from 'three';

import {
  TILE_GENERATIONS,
  TILE_GENERATIONS_URBAN,
  TILE_GENERATIONS_URBAN_OUTSKIRT,
} from '../../constants/map';
import { getFBM } from './fbm';

const noiseWorld = getFBM(TILE_GENERATIONS.SeedWorld);
const noiseUrban = getFBM(TILE_GENERATIONS_URBAN.SeedWorld);

export const getNoiseWorldByCoords = (baseX: number, baseY: number) => {
  return (
    noiseWorld(
      new Vector2(baseX, baseY).multiplyScalar(TILE_GENERATIONS.Scale),
    ) * TILE_GENERATIONS.Height
  );
};

export const getNoiseUrbanByCoords = (baseX: number, baseY: number) => {
  return (
    noiseUrban(
      new Vector2(baseX, baseY).multiplyScalar(TILE_GENERATIONS_URBAN.Scale),
    ) *
    TILE_GENERATIONS_URBAN.Height *
    TILE_GENERATIONS_URBAN.HeightMultiplier
  );
};

export const getNoiseUrbanOutskirtByCoords = (baseX: number, baseY: number) => {
  return (
    noiseUrban(
      new Vector2(baseX, baseY).multiplyScalar(
        TILE_GENERATIONS_URBAN_OUTSKIRT.Scale,
      ),
    ) *
    TILE_GENERATIONS_URBAN_OUTSKIRT.Height *
    TILE_GENERATIONS_URBAN_OUTSKIRT.HeightMultiplier
  );
};
