import { IHex, IPoint } from '@chiefdom/shared';
import { worldMapHexes } from '../../config/map/setup';

export const getWorldMapTileByScreenOffset = (offset: IPoint) => {
  return worldMapHexes.find(
    h =>
      h.positionHexScreen.x === offset.x && h.positionHexScreen.y === offset.y,
  );
};

export const getWorldMapTile = ({ q, r, s }: IHex) => {
  return worldMapHexes.find(
    h =>
      h.positionHex.x === q && h.positionHex.y === r && h.positionHex.z === s,
  );
};

export const getWorldMapTileHeightAtPoint = (hex: IHex) =>
  getWorldMapTile(hex)?.height || 0;
