import { FC, useState } from 'react';
import { SignUpForm } from './components/SignUpForm';

import { SignInForm } from './components/SignInForm';
import { ShardForm } from './ShardForm';

type LoginOnboardingPageProps = {
  hasAccount: boolean;
  hasShard: boolean;
};

export const LoginOnboardingPage: FC<LoginOnboardingPageProps> = ({
  hasAccount,
  hasShard,
}) => {
  const [page, setPage] = useState<'login' | 'signup'>('login');

  if (!hasAccount) {
    switch (page) {
      case 'login':
        return <SignInForm onSignUpClicked={() => setPage('signup')} />;
      case 'signup':
        return <SignUpForm onLoginClicked={() => setPage('login')} />;
    }
  }

  if (!hasShard) {
    return <ShardForm />;
  }

  return <>All good now.</>;
};
