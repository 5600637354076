import { useLayoutEffect } from 'react';
import { useMapStore } from '../../../store/game/map-store';
import { T3Realm } from '../T3Realm/T3Realm';

export const T3RealmList = () => {
  const realms = useMapStore(state => state.realms);
  const updateRealms = useMapStore(state => state.updateRealms);

  useLayoutEffect(() => {
    updateRealms(true);
  }, [updateRealms]);

  return (
    <group>
      {realms.map(item => (
        <T3Realm key={item.id} realmId={item.id} />
      ))}
    </group>
  );
};
