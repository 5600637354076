import { useMemo, useState } from 'react';
import { useHoldingStore } from '../../../store/game/holding-store';
import { BuildingList } from '../../../scenes/CityScene/components/BuildingList';
import { BuildingEditor } from './components/BuildingEditor';
import { ButtonProps } from 'primereact/button';
import { RightDock } from '../../2_molecules/RightDock/RightDock';
import { useIsRulerOfSelectedHolding } from '../../../hooks/useIsRulerOfSelectedHolding';
import { CityUiHeader } from './components/CityUiHeader';

export const CityMapUI = () => {
  const selectHolding = useHoldingStore(state => state.setSelectedHolding);
  const isRuler = useIsRulerOfSelectedHolding();

  const [visibleBuildings, setVisibleBuildings] = useState(false);

  const dockItems: ButtonProps[] = useMemo(
    () =>
      [
        ...(isRuler
          ? [
              {
                label: 'Build',
                icon: 'pi pi-building',
                onClick: () => setVisibleBuildings(true),
              },
            ]
          : []),
        {
          label: 'World',
          icon: 'pi pi-globe',
          onClick: () => selectHolding(0),
        },
      ].filter(Boolean),
    [selectHolding, isRuler],
  );
  return (
    <>
      <CityUiHeader />
      <RightDock model={dockItems} />
      <BuildingList
        visible={visibleBuildings}
        onHide={() => setVisibleBuildings(false)}
      />
      <BuildingEditor />
    </>
  );
};
