import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FC, useCallback, useMemo } from 'react';
import { HoldingType, ICoa } from '../../../../types/models';
import { CoaEditor } from '../../1_atoms/CoaEditor/CoaEditor';
import { getRandomHoldingName } from '@chiefdom/shared';

export type HoldingBuilderFormState = {
  name: string;
  type: HoldingType;
  coatOfArms: ICoa;
};

export type HoldingBuilderFormProps = {
  value: HoldingBuilderFormState;
  availableTypes?: HoldingType[];
  onChange: (value: HoldingBuilderFormState) => void;
};

export const HoldingBuilderForm: FC<HoldingBuilderFormProps> = ({
  value,
  availableTypes = [HoldingType.CASTLE, HoldingType.TEMPLE, HoldingType.CITY],
  onChange,
}) => {
  const handleValueChange = useCallback(
    (field: keyof HoldingBuilderFormState) => (event: any) => {
      onChange({ ...value, [field]: event.target.value });
    },
    [onChange, value],
  );
  const handleCoaChange = useCallback(
    (coa: ICoa) => {
      onChange({ ...value, coatOfArms: coa });
    },
    [value, onChange],
  );

  const handleRandomName = useCallback(() => {
    onChange({ ...value, name: getRandomHoldingName() });
  }, [onChange, value]);

  const renderName = useMemo(() => {
    const name = value.name || 'Holding';
    switch (value.type) {
      case HoldingType.CASTLE:
        return `Castle of ${name}`;
      case HoldingType.CITY:
        return `City of ${name}`;
      case HoldingType.TEMPLE:
        return `Temple of ${name}`;
      default:
        return `Hold of ${name} tribe`;
    }
  }, [value.name, value.type]);

  const options = useMemo(
    () => availableTypes.map(item => item),
    [availableTypes],
  );

  return (
    <>
      <div className="mb-3">
        <label htmlFor="name" className="mb-2 text-sm block">
          Holding Name
        </label>
        <div className="flex flex-row justify-between items-center gap-2">
          <InputText
            id="name"
            name="name"
            value={value.name}
            onChange={handleValueChange('name')}
            placeholder="Holding Name"
            className="w-full"
          />
          <Button
            icon="pi pi-refresh flex-shrink-0"
            onClick={handleRandomName}
          />
          {options.length > 1 && (
            <Dropdown
              value={value.type}
              options={options}
              onChange={handleValueChange('type')}
            />
          )}
        </div>
      </div>
      <div className="mb-3">
        <p className="text-xs text-gray-400 text-center">{renderName}</p>
        <CoaEditor value={value.coatOfArms} onChange={handleCoaChange} />
      </div>
    </>
  );
};
