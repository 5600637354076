import './uncache';
import '@formatjs/intl-numberformat/polyfill';
import React, { Suspense } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import ReactDOM from 'react-dom/client';
import './index.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import App from './app/App';
import { AssetLoader } from './app/components/3_organism/AssetLoader/AssetLoader';
import { AuthenticationProvider } from './app/components/5_providers/AuthenticationProvider/AuthenticationProvider';
import { GameServerProvider } from './app/components/5_providers/GameServerProvider/GameServerProvider';

import reportWebVitals from './reportWebVitals';
import { ErrorDialog } from './app/components/5_providers/ErrorDialog/ErrorDialog';
import { SocketProvider } from './app/components/5_providers/SocketProvider/SockerProvider';
import { LevaProvider } from './app/components/0_meta/LevaProvider/LevaProvider';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <GameServerProvider>
        <Suspense>
          <SocketProvider>
            <App />
          </SocketProvider>
        </Suspense>
      </GameServerProvider>
    </AuthenticationProvider>
    <AssetLoader />
    <ErrorDialog />
    <LevaProvider />
  </React.StrictMode>,
);

reportWebVitals();
