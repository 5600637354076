import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { createUserWithEmailAndPassword } from 'firebase/auth';

import { auth } from '../../../../../config/firebase';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { httpErrorEvents } from '../../../../../events/http-error';
import { ScrollableArea } from '../../../1_atoms/ScrollabelArea/ScrollableArea';

type FormState = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

type Props = {
  onLoginClicked: () => void;
};

export const SignUpForm: FC<Props> = ({ onLoginClicked }) => {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<FormState>({
    email: '',
    password: '',
    passwordConfirmation: '',
  });

  const handleFormChange = useCallback(
    (key: keyof FormState) => (e: ChangeEvent<HTMLInputElement>) =>
      setForm(p => ({ ...p, [key]: e.target.value })),
    [],
  );

  const isFormValid = useMemo(
    () =>
      Object.values(form).every(value => !!value && value.length > 5) &&
      form.password === form.passwordConfirmation,
    [form],
  );

  const handleSignUp = useCallback(() => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, form.email, form.password)
      .then(credentials => {})
      .catch(error => {
        httpErrorEvents.push(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form]);

  return (
    <ScrollableArea>
      <main className="w-full min-h-full flex flex-col items-center justify-center p-8">
        <div className="max-w-xs w-full bg-gray-400 rounded p-4">
          <form onSubmit={handleSignUp} method="post">
            <div className="mb-4">
              <InputText
                name="email"
                value={form.email}
                onChange={handleFormChange('email')}
                placeholder="Email"
                autoComplete="email"
              />
            </div>

            <div className="mb-4">
              <Password
                name="password"
                value={form.password}
                onChange={handleFormChange('password')}
                placeholder="Password"
                className="w-full"
                autoComplete="new-password"
              />
            </div>

            <div className="mb-4">
              <Password
                name="password-confirmation"
                value={form.passwordConfirmation}
                onChange={handleFormChange('passwordConfirmation')}
                placeholder="Password Confirmation"
                className="w-full"
                autoComplete="new-password"
                feedback={false}
              />
            </div>

            <Button
              onClick={handleSignUp}
              disabled={loading || !isFormValid}
              type="submit"
            >
              Sign up
            </Button>

            <Button link onClick={onLoginClicked}>
              Login
            </Button>
          </form>
        </div>
      </main>
    </ScrollableArea>
  );
};
