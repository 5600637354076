import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { signInWithEmailAndPassword } from 'firebase/auth';

import { auth } from '../../../../../config/firebase';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { httpErrorEvents } from '../../../../../events/http-error';
import { ScrollableArea } from '../../../1_atoms/ScrollabelArea/ScrollableArea';

type FormState = {
  email: string;
  password: string;
};

type Props = {
  onSignUpClicked: () => void;
};

export const SignInForm: FC<Props> = ({ onSignUpClicked }) => {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<FormState>({
    email: '',
    password: '',
  });

  const handleFormChange = useCallback(
    (key: keyof FormState) => (e: ChangeEvent<HTMLInputElement>) =>
      setForm(p => ({ ...p, [key]: e.target.value })),
    [],
  );

  const isFormValid = useMemo(
    () => Object.values(form).every(value => !!value && value.length > 5),
    [form],
  );

  const handleLogin = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      signInWithEmailAndPassword(auth, form.email, form.password)
        .then(credentials => {})
        .catch(error => {
          httpErrorEvents.push(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [form],
  );

  return (
    <ScrollableArea>
      <main className="w-full min-h-full flex flex-col items-center justify-center p-8">
        <div className="max-w-xs w-full bg-gray-400 rounded p-4">
          <form onSubmit={handleLogin} method="post">
            <div className="mb-4">
              <InputText
                name="email"
                value={form.email}
                onChange={handleFormChange('email')}
                placeholder="Email"
                autoComplete="email"
              />
            </div>
            <div className="mb-4">
              <Password
                name="email"
                value={form.password}
                onChange={handleFormChange('password')}
                placeholder="Password"
                autoComplete="current-password"
                feedback={false}
              />
            </div>

            <Button
              disabled={loading || !isFormValid}
              type="submit"
              label="Login"
            />

            <Button onClick={onSignUpClicked} link>
              Sign up
            </Button>
          </form>
        </div>
      </main>
    </ScrollableArea>
  );
};
