import { Sex, TitleRank } from '../../../../types/models';
import { useGameStore } from '../../../store/game-store';
import { CoatOfArms } from '../../1_atoms/CoatOfArms/CoatOfArms';

export const PlayerCharacterNameRenderer = () => {
  const character = useGameStore(state => state.player?.character);
  const dynasty = useGameStore(state => state.player?.dynasty);
  const title = useGameStore(state => state.player?.realm?.title);

  if (!character || !dynasty || !title) {
    return null;
  }

  return (
    <div className="absolute top-8 left-2 md:top-0">
      <div className="flex flex-row justify-start items-start">
        <CoatOfArms coa={dynasty.coa} className="w-12 md:w-24 interactive" />
        <div>
          <div className="text-xs px-2 rounded-br interactive bg-opacity-50 bg-gray-700 md:shadow-gray-700/10 md:shadow-lg md:py-2 md:px-4 md:rounded-br-3xl text-white">
            {makeCharacterTitle(character.sex, title.rank)} {character.name}{' '}
            {dynasty.name}
          </div>
          <div className="mt-2 flex flex-row justify-start items-center gap-1 px-2">
            <CoatOfArms coa={title.coa} className="w-3 md:w-6 interactive" />
          </div>
        </div>
      </div>
    </div>
  );
};

// const makeCharacterName = (character: ICharacter, title: ITitle) => {
//   return `${makeCharacterTitle(character.sex, title.rank)} ${
//     character.name
//   } of ${title.name}`;
// };

const makeCharacterTitle = (sex: Sex, title: TitleRank) => {
  const isMale = sex === Sex.MALE;
  switch (title) {
    case TitleRank.BARONY:
      return isMale ? 'Headman' : 'Headwoman';
    case TitleRank.COUNTY:
      return isMale ? 'Chief' : 'Chieftess';
    case TitleRank.DUCHY:
      return isMale ? 'Duke' : 'Duchess';
    case TitleRank.KINGDOM:
      return isMale ? 'King' : 'Queen';
    case TitleRank.EMPIRE:
      return isMale ? 'Emperor' : 'Empress';
    default:
      return '';
  }
};
