import { useThree } from '@react-three/fiber';

import { FC, useEffect, useMemo, useRef } from 'react';

import {
  Mesh,
  Color,
  MeshPhongMaterial,
  ColorRepresentation,
  CylinderGeometry,
} from 'three';
import { useMapStore } from '../../../store/game/map-store';
import { createRealmMesh } from './utils';
import { WORLD_LAYOUT, hexToScreen } from '@chiefdom/shared';
import { IRealm } from '../../../../types/models';
import { HoldingLayout } from '../../../scenes/WorldScene/components/HoldingLayout/HoldingLayout';
import { NONE } from '../../../../constants/general';

type T3RealmProps = {
  realmId: number;
};

export const T3Realm: FC<T3RealmProps> = ({ realmId }) => {
  const meshRef = useRef<Mesh>();

  const realm = useMapStore(
    state =>
      state.realms.find(item => item.id === realmId) ||
      ({ holdings: [] } as unknown as IRealm),
  );

  const scene = useThree(({ scene }) => scene);

  useEffect(() => {
    if (!realm.holdings.length) return;

    meshRef.current = createRealmMesh(realm);
    // meshRef.current.position.set(realm.capital.x, 0, realm.capital.y);
    scene.add(meshRef.current);

    const material = new MeshPhongMaterial({
      color: new Color(
        `#${realm.title.coa.primaryColor}` as ColorRepresentation,
      ),
      transparent: true,
      opacity: 0.3,
    });

    const cylinder = new Mesh(
      new CylinderGeometry(1 / 2, 1 / 2, 50, 6),
      material,
    );
    cylinder.rotateY(Math.PI / 2);
    const pos = hexToScreen(WORLD_LAYOUT, realm.capital);
    cylinder.position.set(pos.x, 10, pos.y);

    scene.add(cylinder);

    return () => {
      scene.remove(cylinder);
      if (meshRef.current) {
        scene.remove(meshRef.current);
      }
    };
  }, [realm, scene]);

  const holdings = useMemo(
    () => realm.holdings.filter(item => item.type !== NONE),
    [realm.holdings],
  );

  return (
    <>
      {holdings.map(item => (
        <HoldingLayout
          key={item.id}
          holding={item}
          capital={item.id === realm.capital.id}
        />
      ))}
    </>
  );
};
