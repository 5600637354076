import { FC } from 'react';
import { Message } from '../../../../store/chat/chat-system-store';
import { CoatOfArms } from '../../../1_atoms/CoatOfArms/CoatOfArms';

type MessageListProps = {
  messages: Message[];
};
export const MessageList: FC<MessageListProps> = ({ messages }) => {
  return (
    <div className="text-black h-48 overflow-y-auto mb-2 flex flex-col-reverse">
      <div className="py-2 flex flex-col">
        {messages.map(item => (
          <div key={item.id} className="mt-4">
            <div className="flex flex-row justify-start items-center gap-2 text-xs">
              <div className="w-3 flex-shrink-0">
                <CoatOfArms coa={item.author.coa} />
              </div>
              {item.author.name}
            </div>
            <div>{item.text}</div>
          </div>
        ))}
        {messages.length === 0 && (
          <p className="text-xs">There is no messages. Say hi now!</p>
        )}
      </div>
    </div>
  );
};
