import { FC } from 'react';
import { ExtrudeGeometry, Shape, Vector2 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { ICoa, TitleRank } from '../../../../types/models';

type CaoProps = {
  coa: ICoa;
  rank?: TitleRank;
  scale?: number;
};

const options = {
  depth: 0.01,
  bevelEnabled: false,
  bevelSegments: 2,
  steps: 1,
  bevelSize: 1,
  bevelThickness: 1,
};

// M0 0 L0 3 L5 1 L5 0Z
const shape1 = new Shape([
  new Vector2(0, 0),
  new Vector2(0, 3),
  new Vector2(5, 1),
  new Vector2(5, 0),
]);

const geo1 = new ExtrudeGeometry(shape1, options);

// M0 3 L0 4 C0 4 1 5 2.5 5 C4 5 5 4 5 4 L5 1z
const shape2 = new Shape([
  new Vector2(0, 3),
  new Vector2(0, 4),
  new Vector2(1, 5),
  new Vector2(2.5, 5),
  new Vector2(4, 5),
  new Vector2(5, 4),
  new Vector2(5, 1),
]);
const geo2 = new ExtrudeGeometry(shape2, options);

// todo: add frame depending on rank
export const Coa: FC<CaoProps> = ({ coa, scale = 1 }) => {
  if (!coa) return null;
  return (
    <group
      scale={scale}
      rotation={[0, 0, degToRad(180)]}
      position={[scale * 2.5, 0, 0]}
    >
      <mesh geometry={geo1}>
        <meshBasicMaterial attach="material" color={`#${coa.primaryColor}`} />
      </mesh>
      <mesh geometry={geo2}>
        <meshBasicMaterial attach="material" color={`#${coa.secondaryColor}`} />
      </mesh>
    </group>
  );
};
