import { FC } from 'react';
import { useControls } from 'leva';
import { MapControls } from '../../2_molecules/MapControls/MapControls';
import { useFrame } from '@react-three/fiber';
import { DEV } from '../../../../constants/general';

document.addEventListener('gesturestart', e => e.preventDefault());
document.addEventListener('gesturechange', e => e.preventDefault());

type CameraControlsProps = {
  enabled?: boolean;
};

export const WorldCameraControls: FC<CameraControlsProps> = ({
  enabled = true,
}) => {
  const leva = useControls('Camera', {
    panSpeed: 1,
    enableRotate: DEV,
    enableZoom: DEV,
    enablePan: true,
    enableDamping: true,
    minDistance: 1,
    maxDistance: 200,
    axis: { options: ['none', 'top', 'front', 'back', 'left', 'right'] },
  });

  useFrame(({ camera }) => {
    if (DEV && leva.axis !== 'none') {
      switch (leva.axis) {
        case 'top':
          camera.rotation.set(-Math.PI / 2, 0, 0);
          break;
        case 'front':
          camera.rotation.set(0, Math.PI * 2, 0);
          break;
        case 'back':
          camera.rotation.set(0, Math.PI, 0);
          break;
        case 'left':
          camera.rotation.set(0, -Math.PI / 2, 0);
          break;
        case 'right':
          camera.rotation.set(0, Math.PI / 2, 0);
          break;
      }
    }
  });

  return (
    <MapControls
      enabled={enabled}
      panSpeed={leva.panSpeed}
      enablePan={leva.enablePan}
      enableDamping={leva.enableDamping}
      enableRotate={leva.enableRotate}
      enableZoom={leva.enableZoom}
      minDistance={leva.minDistance}
      maxDistance={leva.maxDistance}
    />
  );
};
