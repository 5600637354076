import { FC, PropsWithChildren, useEffect } from 'react';

import { useGameStore } from '../../../store/game-store';
import { GameOnboardingPage } from '../../6_pages/GameOnboardingPage/GameOnboardingPage';

export const GameServerProvider: FC<PropsWithChildren> = ({ children }) => {
  const player = useGameStore(state => state.player);
  const loading = useGameStore(state => state.loading);
  const updatePlayer = useGameStore(state => state.updatePlayer);

  useEffect(() => {
    updatePlayer(true);
  }, [updatePlayer]);

  if (loading)
    return <div className="bg-black text-white">Loading game data...</div>;

  if (!player) return <GameOnboardingPage />;

  return <>{children}</>;
};
