import { FC, useCallback, useEffect, useState } from 'react';

import { useShardStore } from '../../../store/shard-store';
import { ShardType } from '../../../../types/login-server';
import { getShardList } from '../../../../utils/clients/ls';

export const ShardForm: FC = () => {
  const setShard = useShardStore(state => state.setShard);

  const [loading, setLoading] = useState(true);
  const [shards, setShards] = useState<ShardType[]>([]);

  useEffect(() => {
    setLoading(true);
    getShardList()
      .then(setShards)
      .finally(() => setLoading(false));
  }, []);

  const handleShardSelection = useCallback(
    (shard: ShardType) => () => setShard(shard),
    [setShard],
  );

  return (
    <main className="w-full h-full flex flex-col items-center justify-center p-8 gap-3">
      <h1>Choose a World:</h1>
      {shards.map(shard => (
        <button
          key={shard.id}
          className="max-w-xs w-full bg-gray-400 rounded p-4 flex flex-row justify-start items-center gap-5 hover:bg-gray-300 text-left"
          onClick={handleShardSelection(shard)}
        >
          <div className="text-lg">#{shard.id}.</div>
          <div className="flex flex-col items-start">
            <div>{shard.name}</div>
            <div className="text-xs">{shard.description}</div>
          </div>
        </button>
      ))}
      {loading && <div>loading...</div>}
    </main>
  );
};
