import { useFrame } from '@react-three/fiber';

import { FC, useMemo, useRef } from 'react';

import * as THREE from 'three';
import { Color } from 'three';

import { WaterSettings } from '../../../../../config/water-config';

type WaterProps = {
  settings: WaterSettings;
};

const Water: FC<WaterProps> = ({ settings }) => {
  const vertexShader = `
    precision mediump float;
    uniform float u_time;

    varying vec2 vUv;
    
    vec3 mod289(vec3 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
    }
    
    vec4 mod289(vec4 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
    }
    
    vec4 permute(vec4 x) {
         return mod289(((x*34.0)+1.0)*x);
    }
    
    vec4 taylorInvSqrt(vec4 r)
    {
      return 1.79284291400159 - 0.85373472095314 * r;
    }
    
    float snoise(vec3 v) {
      const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
      const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
      
      // First corner
      vec3 i  = floor(v + dot(v, C.yyy) );
      vec3 x0 =   v - i + dot(i, C.xxx) ;
      
      // Other corners
      vec3 g = step(x0.yzx, x0.xyz);
      vec3 l = 1.0 - g;
      vec3 i1 = min( g.xyz, l.zxy );
      vec3 i2 = max( g.xyz, l.zxy );
    
      //   x0 = x0 - 0.0 + 0.0 * C.xxx;
      //   x1 = x0 - i1  + 1.0 * C.xxx;
      //   x2 = x0 - i2  + 2.0 * C.xxx;
      //   x3 = x0 - 1.0 + 3.0 * C.xxx;
      vec3 x1 = x0 - i1 + C.xxx;
      vec3 x2 = x0 - i2 + C.yyy; // 2.0*C.x = 1/3 = C.y
      vec3 x3 = x0 - D.yyy;      // -1.0+3.0*C.x = -0.5 = -D.y
      
      // Permutations
      i = mod289(i);
      vec4 p = permute( permute( permute(
                 i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
               + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
               + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
               
      // Gradients: 7x7 points over a square, mapped onto an octahedron.
      // The ring size 17*17 = 289 is close to a multiple of 49 (49*6 = 294)
      float n_ = 0.142857142857; // 1.0/7.0
      vec3  ns = n_ * D.wyz - D.xzx;
    
      vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)
    
      vec4 x_ = floor(j * ns.z);
      vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
    
      vec4 x = x_ *ns.x + ns.yyyy;
      vec4 y = y_ *ns.x + ns.yyyy;
      vec4 h = 1.0 - abs(x) - abs(y);
    
      vec4 b0 = vec4( x.xy, y.xy );
      vec4 b1 = vec4( x.zw, y.zw );
    
      //vec4 s0 = vec4(lessThan(b0,0.0))*2.0 - 1.0;
      //vec4 s1 = vec4(lessThan(b1,0.0))*2.0 - 1.0;
      vec4 s0 = floor(b0)*2.0 + 1.0;
      vec4 s1 = floor(b1)*2.0 + 1.0;
      vec4 sh = -step(h, vec4(0.0));
    
      vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
      vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
    
      vec3 p0 = vec3(a0.xy,h.x);
      vec3 p1 = vec3(a0.zw,h.y);
      vec3 p2 = vec3(a1.xy,h.z);
      vec3 p3 = vec3(a1.zw,h.w);
      
      // Normalise gradients
      vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
      p0 *= norm.x;
      p1 *= norm.y;
      p2 *= norm.z;
      p3 *= norm.w;
      
      // Mix final noise value
      vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
      m = m * m;
      return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                    dot(p2,x2), dot(p3,x3) ) );
    }
    
    varying float vZ;
    varying vec3 vPosition;
    
    void main() {
      vUv = uv;
      vPosition = position;
    
      vec3 pos = position;
      float noiseFreq = 3.5;
      float noiseAmp = 0.15; 
      vec3 noisePos = vec3(pos.x * noiseFreq + u_time, pos.y, pos.z);
      pos.z += snoise(noisePos) * noiseAmp;
      vZ = pos.z;
    
      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
    }
  `;

  const fragmentShader2 = `
    varying vec2 vUv;
    uniform vec3 u_colorA;
    uniform vec3 u_colorB;
    varying float vZ;
    
    uniform vec3 fogColor;
    uniform float fogNear;
    uniform float fogFar;

    void main() {
      vec3 color = mix(u_colorA, u_colorB, vZ * 2.0 + 0.5); 
      gl_FragColor = vec4(color, 0.85);
      
      #ifdef USE_FOG
          #ifdef USE_LOGDEPTHBUF_EXT
              float depth = gl_FragDepthEXT / gl_FragCoord.w;
          #else
              float depth = gl_FragCoord.z / gl_FragCoord.w;
          #endif
          float fogFactor = smoothstep( fogNear, fogFar, depth );
          gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
      #endif
    }
  `;
  const mesh = useRef<any>();

  const uniforms = useMemo(
    () => ({
      u_time: {
        value: 0.0,
      },
      u_colorA: { value: new Color('#3f505d') },
      u_colorB: { value: new Color('#85a2b0') },
      fogColor: { type: 'c', value: new THREE.Color(0xb6b6b6) },
      fogNear: { type: 'f', value: 150 },
      fogFar: { type: 'f', value: 75 },
    }),
    [],
  );

  useFrame(state => {
    const { clock } = state;
    if (!mesh.current) return;
    mesh.current.material.uniforms.u_time.value = clock.getElapsedTime() / 3;
  });

  return (
    <mesh
      ref={mesh}
      position={[0, settings.offset, 0]}
      rotation={[-Math.PI / 2, 0, 0]}
    >
      <planeGeometry
        args={[
          settings.width,
          settings.height,
          settings.widthSegment,
          settings.heightSegment,
        ]}
      />
      <shaderMaterial
        fragmentShader={fragmentShader2}
        vertexShader={vertexShader}
        uniforms={uniforms}
        fog={true}
        transparent={settings.transparent}
      />
    </mesh>
  );
};

export default Water;
