import { Cost } from '@chiefdom/collections';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Nullable } from '../../types';
import { IPlayer } from '../../types/models';
import { gs } from '../../utils/clients/gs';

type GameStoreState = {
  loading: boolean;
  player: Nullable<IPlayer>;
  resources: Partial<Cost>;
  setLoading: (loading: boolean) => void;
  setPlayer: (player: Nullable<IPlayer>) => void;
  updatePlayer: (loading?: boolean) => void;
  setResources: (resources: Partial<Cost>) => void;
  updateResources: () => void;
};

export const useGameStore = create(
  immer<GameStoreState>(set => ({
    loading: false,
    player: null,
    resources: {},
    setLoading: loading =>
      set(draft => {
        draft.loading = loading;
      }),
    setPlayer: player =>
      set(draft => {
        draft.player = player;
      }),
    updatePlayer: async (loading?: boolean) => {
      if (loading) {
        set(draft => {
          draft.loading = true;
        });
      }
      gs.get('/player')
        .then(({ data }) =>
          set(draft => {
            draft.player = data;
            // todo: this is a hack, we should be able to get the resources from the player object
            const { gold, wood, stone, iron, food, population } = data;
            draft.resources = {
              gold,
              wood,
              stone,
              iron,
              food,
              population,
            };
          }),
        )
        .finally(() => {
          set(draft => {
            draft.loading = false;
          });
        });
    },
    setResources: resources => {
      set(draft => {
        draft.resources = resources;
      });
    },
    updateResources: async () => {
      gs.get('/player/resources').then(({ data }) =>
        set(draft => {
          draft.resources = data;
        }),
      );
    },
  })),
);

export const usePlayerCharacter = () =>
  useGameStore(state => state.player?.character);

export const useCharacterHoldings = () =>
  useGameStore(state => state.player?.character?.holdings || []);

export const useCharacterHolding = (index: number = 0) =>
  useGameStore(state => state.player?.realm?.holdings?.[index]);

export const usePlayerCapital = () =>
  useGameStore(state => state.player?.realm?.capital);

export const useGetPlayerResources = () =>
  useGameStore(state => state.resources);
