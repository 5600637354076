import { Euler, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';

export const NONE = 'NONE' as any;

export const PRODUCTION = process.env.NODE_ENV === 'production';
export const DEV = process.env.NODE_ENV === 'development';

export const CAMERA_POSITION = new Vector3(0, 20, 0);
export const CAMERA_ROTATION = new Euler(degToRad(-60), 0, 0);
export const CAMERA_OFFSET = new Vector3(0, 0, 7);
export const ZERO = new Vector3(0, 0, 0);
