import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { useEffect, useMemo } from 'react';
import { gs } from '../../../../utils/clients/gs';
import { createIo } from '../../../../utils/clients/gs-socket';
import { Dialog } from 'primereact/dialog';
import { MessageList } from './components/MessageList';
import { MessageBox } from './components/MessageBox';
import { useChatSystemStore } from '../../../store/chat/chat-system-store';
import { CoatOfArms } from '../../1_atoms/CoatOfArms/CoatOfArms';

export const ChatSystem = () => {
  const messages = useChatSystemStore(state => state.messages);
  const isChatOpen = useChatSystemStore(state => state.isChatOpen);
  const lastReadTimestamp = useChatSystemStore(
    state => state.lastReadTimestamp,
  );
  const initMessages = useChatSystemStore(state => state.initMessages);
  const addMessage = useChatSystemStore(state => state.addMessage);
  const setChatOpen = useChatSystemStore(state => state.setChatOpen);

  useEffect(() => {
    gs.get('/chat').then(res => {
      initMessages(res.data.messages.reverse());
    });

    const socket = createIo();
    socket.on('chat:message', (data: any) => {
      addMessage(data);
    });

    return () => {
      socket.off('chat:message');
    };
  }, [addMessage, initMessages]);

  const lastMessage = useMemo(() => messages[messages.length - 1], [messages]);
  const unreadMessages = useMemo(
    () =>
      messages.filter(
        item => new Date(item.createdAt).getTime() > lastReadTimestamp,
      ).length,
    [lastReadTimestamp, messages],
  );

  return (
    <>
      <div className="absolute bottom-2 left-2 right-2 md:w-64 md:right-auto">
        <div className="flex flex-row justify-start items-center gap-4">
          <Button
            className="interactive"
            type="submit"
            size="small"
            onClick={() => setChatOpen(true)}
          >
            <i className="pi pi-envelope p-overlay-badge">
              {unreadMessages > 0 && (
                <Badge
                  severity="danger"
                  value={unreadMessages > 99 ? '99+' : unreadMessages}
                  style={{ fontSize: '0.4rem', padding: 0 }}
                />
              )}
            </i>
          </Button>
          <div className="non-interactive text-white">
            {lastMessage ? (
              <div className="flex flex-col">
                <div className="flex flex-row justify-start items-center gap-2 text-xs">
                  <div className="w-3 flex-shrink-0">
                    <CoatOfArms coa={lastMessage.author.coa} />
                  </div>
                  {lastMessage.author.name}
                </div>
                <div>{lastMessage.text}</div>
              </div>
            ) : (
              <>Say hi.</>
            )}
          </div>
        </div>
      </div>
      <Dialog
        visible={isChatOpen}
        onHide={() => setChatOpen(false)}
        className="mx-8 w-full sm:w-96"
        header="Chat"
      >
        <MessageList messages={messages} />
        <MessageBox />
      </Dialog>
    </>
  );
};
