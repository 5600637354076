import { io, Socket } from 'socket.io-client';
import { getGsEndpoint, getIdTokenStatic } from './gs';

// const log = debug('app:socket');
const log = console.log;

let _io: Socket;

export const createIo = () => {
  if (!_io) {
    _io = io(getGsEndpoint()!, {
      path: '/websocket',
      auth: { token: getIdTokenStatic() },
    });
  }
  return _io;
};

export const connectToSockets = () => {
  const socket = createIo();

  socket.auth = { token: getIdTokenStatic() };
  const result = socket.connect();

  socket.on('session', ({ userID }) => {
    log('session received:', { userID });
    // attach the session ID to the next reconnection attempts
    // socket.auth = { sessionID };
    // store it in the localStorage
    // localStorage.setItem("sessionID", sessionID);
    // save the ID of the user
    // socket.userID = userID;
  });

  socket.on('connect', () => {
    log('connected');
  });

  socket.onAny((eventName, ...args) => {
    switch (eventName) {
      default:
        log('unhandled event:', eventName, args);
        break;
    }
  });

  socket.on('disconnect', () => {
    log('disconnected');
  });

  return result;
};
