import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useState } from 'react';
import { httpErrorEvents } from '../../../../events/http-error';

export const ErrorDialog = () => {
  const [text, setText] = useState<string>();

  const handleNewError = useCallback(
    (error: string) => {
      if (text) return;
      setText(error);
    },
    [text],
  );

  useEffect(() => {
    const sub = httpErrorEvents.subscribe(handleNewError);
    return () => sub.unsubscribe();
  }, [handleNewError]);

  return (
    <Dialog
      visible={!!text}
      onHide={() => setText(undefined)}
      header="There was an error"
    >
      {text}
    </Dialog>
  );
};
