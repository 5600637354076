import { structures } from '@chiefdom/collections';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FC, useCallback, useMemo, useState } from 'react';
import { HoldingType, TitleRank } from '../../../../../types/models';
import { gs } from '../../../../../utils/clients/gs';
import { randomHexColor } from '../../../../../utils/colors';
import { useVerifyCost } from '../../../../hooks/useVerifyCost';
import { useGameStore } from '../../../../store/game-store';
import { CostRenderer } from '../../../1_atoms/CostRenderer/CostRenderer';
import {
  HoldingBuilderForm,
  HoldingBuilderFormState,
} from '../../HoldingBuilderForm/HoldingBuilderForm';
import { Point, getRandomHoldingName } from '@chiefdom/shared';

type HoldingCreationDialogProps = {
  visible: boolean;
  onClose: () => void;
  onComplete: () => void;
  realmRank?: TitleRank;
  selection: Point | null;
  type: string;
};

export const HoldingCreationDialog: FC<HoldingCreationDialogProps> = ({
  realmRank,
  selection,
  visible,
  type,
  onClose,
  onComplete,
}) => {
  const [form, setForm] = useState<HoldingBuilderFormState>({
    name: getRandomHoldingName(),
    type:
      realmRank === TitleRank.BARONY ? HoldingType.TEMPLE : HoldingType.CASTLE,
    coatOfArms: {
      shape: 'circle',
      primaryColor: randomHexColor(),
      secondaryColor: randomHexColor(),
    },
  });

  const handleSubmit = useCallback(async () => {
    if (!selection) return;
    if (realmRank === TitleRank.BARONY) {
      // it's onboarding flow, create new temple holding and new county
      await gs
        .post('/onboarding/holding', {
          name: form.name,
          x: selection.x,
          y: selection.y,
          coatOfArms: form.coatOfArms,
        })
        .then(() => {
          onComplete();
        });
    } else {
      // it's not first holding, allow to create new county with castle holding

      if (type === 'county') {
        await gs
          .post('/holdings/county', {
            name: form.name,
            x: selection.x,
            y: selection.y,
            coatOfArms: form.coatOfArms,
          })
          .then(() => {
            onComplete();
          });
        return;
      }

      if (type === 'barony') {
        await gs
          .post('/holdings/barony', {
            name: form.name,
            x: selection.x,
            y: selection.y,
            type: form.type,
            coatOfArms: form.coatOfArms,
          })
          .then(() => {
            onComplete();
          });
        return;
      }

      alert('Unknown error');
    }
  }, [
    form.coatOfArms,
    form.name,
    form.type,
    onComplete,
    realmRank,
    selection,
    type,
  ]);

  const resources = useGameStore(state => state.resources);
  const structure = useMemo(
    () =>
      structures.find(
        item =>
          item.id === (form.type as any) && item.isHolding && item.level === 1,
      ),
    [form.type],
  );

  const { valid } = useVerifyCost(structure?.cost || {}, resources);

  return (
    <Dialog
      visible={visible}
      onHide={onClose}
      header={type === 'county' ? 'Create new County' : 'Build new Holding'}
      className="w-full max-w-md"
    >
      <HoldingBuilderForm
        value={form}
        onChange={setForm}
        availableTypes={buildingTypes(realmRank!, type)}
      />
      <h5 className="mb-2 font-semibold">Cost</h5>
      <CostRenderer cost={structure?.cost || {}} available={resources} />
      <div className="mt-8 text-center">
        <Button
          label="Create"
          icon="pi pi-check"
          onClick={handleSubmit}
          disabled={!valid}
        />
      </div>
    </Dialog>
  );
};

const buildingTypes = (rank: TitleRank, toBuild: string) => {
  if (toBuild === 'nothing') return [];
  if (toBuild === 'county') return [HoldingType.CASTLE];
  if (toBuild === 'barony') {
    if (rank === TitleRank.BARONY) return [HoldingType.TEMPLE];
    return [HoldingType.CASTLE, HoldingType.TEMPLE, HoldingType.CITY];
  }
};
