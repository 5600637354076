import { useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { useGameStore } from '../../../../store/game-store';
import { WORLD_LAYOUT, hexToScreen } from '@chiefdom/shared';
import {
  CAMERA_OFFSET,
  CAMERA_POSITION,
} from '../../../../../constants/general';

// move camera to the players primary holding when game starts
export const PlayerSeeker = () => {
  const capital = useGameStore(state => ({
    exists: !!state.player?.realm?.capital,
    q: state.player?.realm?.capital.q || 0,
    r: state.player?.realm?.capital.r || 0,
    s: state.player?.realm?.capital.s || 0,
  }));

  const cameraSet = useRef<boolean>(false);
  const camera = useThree(({ camera }) => camera);

  useEffect(() => {
    if (cameraSet.current) {
      return;
    }

    if (!capital.exists) {
      return;
    }

    const hex = hexToScreen(WORLD_LAYOUT, {
      q: capital.q,
      r: capital.r,
      s: capital.s,
    });

    camera.position.set(
      hex.x + CAMERA_OFFSET.x,
      CAMERA_POSITION.y + CAMERA_OFFSET.y,
      hex.y + CAMERA_OFFSET.z,
    );

    cameraSet.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capital.q, capital.r, capital.s, capital.exists]);

  return null;
};
