import { useGLTF } from '@react-three/drei';

import { FC, useEffect, useRef } from 'react';

import * as THREE from 'three';
import { InstancedMesh, MathUtils } from 'three';
import { GLTF } from 'three-stdlib';

import { models } from '../../../../../data/models';
import { worldMapAssetsSetup } from '../../../../../config/map/setup';

const tempV4 = new THREE.Object3D();
type GLTFResult = GLTF & {
  nodes: Record<string, THREE.Mesh>;
  materials: Record<string, THREE.Material>;
};

const InstancedTrees: FC = () => {
  const loaded = useGLTF([models.environment_trees_1]) as GLTFResult[];
  const ref = useRef<InstancedMesh>(null);

  useEffect(() => {
    if (!ref.current) return;
    const mesh = ref.current;

    worldMapAssetsSetup.normalTrees.forEach((asset, i) => {
      tempV4.position.x =
        asset.positionHexScreen.x +
        MathUtils.randFloat(-asset.rngFactor, asset.rngFactor);
      tempV4.position.z =
        asset.positionHexScreen.y +
        MathUtils.randFloat(-asset.rngFactor, asset.rngFactor);
      tempV4.position.y = asset.height + 10;
      tempV4.scale.set(asset.assetScale, asset.assetScale, asset.assetScale);
      tempV4.rotation.set(0, Math.floor(Math.random() * 359), 0);

      tempV4.updateMatrix();
      mesh.setMatrixAt(i, tempV4.matrix);
    });
    mesh.instanceMatrix.needsUpdate = true;
  }, []);

  return (
    <instancedMesh
      ref={ref}
      castShadow
      receiveShadow
      args={[
        loaded[0].nodes.tree_pineGroundB.geometry,
        loaded[0].materials.leafsDark,
        worldMapAssetsSetup.normalTrees.length,
      ]}
      frustumCulled={false}
    />
  );
};

export default InstancedTrees;
