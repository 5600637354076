import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { Nullable } from '../../../types';
import { IHolding, IRealm } from '../../../types/models';
import { gs } from '../../../utils/clients/gs';

type MapStoreState = {
  loading: boolean;
  selected: Nullable<number>;
  holdings: IHolding[];
  realms: IRealm[];
  setLoading: (loading: boolean) => void;
  setHoldings: (holdings: IHolding[]) => void;
  selectHolding: (holdingId: Nullable<number>) => void;
  updateRealms: (loading?: boolean) => void;
};

export const useMapStore = create(
  persist(
    immer<MapStoreState>(set => ({
      loading: false,
      holdings: [],
      realms: [],
      selected: null,
      setLoading: loading =>
        set(draft => {
          draft.loading = loading;
        }),
      setHoldings: holdings =>
        set(draft => {
          draft.holdings = holdings;
        }),
      selectHolding: holdingId =>
        set(draft => {
          draft.selected = holdingId;
        }),
      updateRealms: async (loading?: boolean) => {
        if (loading) {
          set(draft => {
            draft.loading = true;
          });
        }
        gs.get('/map')
          .then(({ data }) =>
            set(draft => {
              draft.realms = data.realms;
              draft.holdings = data.holdings;
            }),
          )
          .finally(() => {
            set(draft => {
              draft.loading = false;
            });
          });
      },
    })),
    {
      name: 'map',
      partialize: state => ({
        holdings: state.holdings,
        realms: state.realms,
      }),
    },
  ),
);
