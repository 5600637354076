import { useCallback, useEffect, useMemo, useState } from 'react';
import { Vector2, Vector3 } from 'three';
import {
  cameraPosition,
  cameraPositionRequests,
} from '../../../../../events/camera-events';
import { useGameStore } from '../../../../store/game-store';
import { ReactComponent as CompassIcon } from '../../../../../assets/icons/compass.svg';
import { WORLD_LAYOUT, hexToScreen } from '@chiefdom/shared';

export const Compass = () => {
  const { q, r, s } = useGameStore(
    state => state.player?.realm.capital || { q: 0, r: 0, s: 0 },
  );

  const [pos, setPos] = useState<Vector2>(new Vector2(0, 0));

  useEffect(() => {
    const unsub = cameraPosition.subscribe(state => {
      setPos(new Vector2(state.x, state.z));
    });
    return () => {
      unsub.unsubscribe();
    };
  }, []);

  const capital = useMemo(() => {
    const { x, y } = hexToScreen(WORLD_LAYOUT, { q, r, s });
    return new Vector2(x, y);
  }, [q, r, s]);

  const position = useMemo(() => {
    const direction = pos.clone().sub(capital).normalize().angle();
    const distance = capital.distanceTo(pos);
    return {
      direction,
      distance,
    };
  }, [capital, pos]);

  const handleClick = useCallback(
    () => cameraPositionRequests.push(new Vector3(capital.x, 0, capital.y)),
    [capital],
  );

  return (
    <div className="absolute right-8 bottom-8 text-white">
      <button
        className="interactive relative transition duration-100"
        onClick={handleClick}
        style={{
          transform: `rotate(${position.direction}rad)`,
        }}
      >
        <CompassIcon className="w-12 h-12" />
        {position.distance > 2 && (
          <div className="bg-red-300 w-2 h-2 rounded-full absolute top-1/2 right--1/2 transform -translate-x-1 -translate-y-1" />
        )}
      </button>
    </div>
  );
};
