import { useFrame } from '@react-three/fiber';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { Vector3 } from 'three';
import Stats from 'stats.js';
import {
  cameraPosition,
  cameraPositionRequests,
} from '../../../../events/camera-events';
import { useSettingsStore } from '../../../store/settings/settings-store';
import { CAMERA_OFFSET, CAMERA_POSITION } from '../../../../constants/general';

export const CameraSeeker = () => {
  const statsRef = useRef<Stats>();
  const devStats = useSettingsStore(state => state.devStats);

  useLayoutEffect(() => {
    if (devStats) {
      statsRef.current = new Stats();
      statsRef.current.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      statsRef.current.dom.style.top = '6.5rem';
      document.body.appendChild(statsRef.current.dom);
    }

    return () => {
      if (statsRef.current) {
        statsRef.current.dom.remove();
      }
    };
  }, [devStats]);

  useEffect(() => {
    const sub = cameraPositionRequests.subscribe(({ x, z }) => {
      newPosRef.current = new Vector3(
        x + CAMERA_OFFSET.x,
        CAMERA_POSITION.y + CAMERA_OFFSET.y,
        z + CAMERA_OFFSET.z,
      );
      newPosAcitiveRef.current = true;
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const newPosRef = useRef<Vector3>();
  const newPosAcitiveRef = useRef(false);
  const lastTime = useRef(0);

  useFrame(({ camera, clock }) => {
    if (statsRef.current) {
      statsRef.current.begin();
    }
    const elapsed = clock.getElapsedTime();
    if (elapsed - lastTime.current > 0.1) {
      lastTime.current = elapsed;
      cameraPosition.push(camera.position);
    }
    if (statsRef.current) {
      statsRef.current.end();
    }
    if (!newPosRef.current || !newPosAcitiveRef.current) {
      return;
    }

    if (camera.position.distanceTo(newPosRef.current) > 0.1) {
      camera.position.lerp(newPosRef.current, 0.1);
    } else {
      newPosAcitiveRef.current = false;
    }
  });

  return null;
};
