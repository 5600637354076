import { MathUtils, Vector2 } from 'three';
import { FBM } from 'three-noise';

import { TILE_GENERATIONS } from '../../constants/map';

export function getFBM(seed: number) {
  const fbm = new FBM({
    seed: seed,
    lacunarity: TILE_GENERATIONS.Detail * 4,
    persistance: TILE_GENERATIONS.Fuzziness * 2,
    // redistribution: tileGeneration.Contrast * 2,
  });

  return (vec3: Vector2) =>
    Math.pow(
      MathUtils.mapLinear(
        fbm.get2(new Vector2(vec3.x, vec3.y)),
        -1, //
        1,
        0,
        1,
      ),
      2,
    );
}
