import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IPlayer } from '../../../types/models';

// todo: move this to a shared file
export type Message = {
  id: number;
  text: string;
  author: IPlayer;
  authorId: number;
  createdAt: string;
};

type ChatSystemStore = {
  messages: Message[];
  lastReadTimestamp: number;
  isChatOpen: boolean;
  setChatOpen: (isOpen: boolean) => void;
  initMessages: (messages: Message[]) => void;
  addMessage: (message: Message) => void;
};

export const useChatSystemStore = create(
  persist(
    immer<ChatSystemStore>((set, get) => ({
      messages: [],
      lastReadTimestamp: 0,
      isChatOpen: false,
      setChatOpen: isOpen => {
        set(draft => {
          draft.isChatOpen = isOpen;
          if (isOpen) {
            draft.lastReadTimestamp = Date.now();
          }
        });
      },
      initMessages: messages => {
        set(draft => {
          draft.messages = messages;
          if (draft.isChatOpen) {
            draft.lastReadTimestamp = Date.now();
          }
        });
      },
      addMessage: message => {
        set(draft => {
          draft.messages.push(message);
          if (draft.isChatOpen) {
            draft.lastReadTimestamp = Date.now();
          }
        });
      },
    })),
    {
      name: 'chat-system_dev',
      partialize: state => ({
        messages: state.messages,
        lastReadTimestamp: state.lastReadTimestamp,
      }),
    },
  ),
);
