import { FC, forwardRef, SVGAttributes } from 'react';
import { ICoa } from '../../../../types/models';

type CoatOfArmsProps = SVGAttributes<SVGElement> & {
  coa: ICoa;
};

export const CoatOfArms: FC<CoatOfArmsProps> = forwardRef<
  SVGSVGElement,
  CoatOfArmsProps
>(({ coa, ...props }, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5 5"
      ref={ref}
      {...props}
    >
      <path
        fill={`#${coa.primaryColor.replace('#', '')}`}
        d="M0 0 L0 3 L5 1 L5 0Z"
      />
      <path
        fill={`#${coa.secondaryColor.replace('#', '')}`}
        d="M0 3 L0 4 C0 4 1 5 2.5 5 C4 5 5 4 5 4 L5 1z"
      />
    </svg>
  );
});
