import { useCallback, useMemo, useState } from 'react';
import { TitleRank } from '../../../../../types/models';
import { useGameStore } from '../../../../store/game-store';
import { useHexStore } from '../../../../store/game/hex-store';
import { useMapStore } from '../../../../store/game/map-store';
import { BottomDock } from '../../../2_molecules/BottomDock/BottomDock';
import { HoldingCreationDialog } from './HoldingCreationDialog';
import { equalHexFilter } from '../../../../../utils/helpers/hex';
import { offsetFromHex } from '@chiefdom/shared';
import { useHoldingStore } from '../../../../store/game/holding-store';
import { NONE } from '../../../../../constants/general';

// allows to create temple holding if it's second holding in realm
// allows to create new castle holding and new county after onboarding
// todo: allow to create new holding in existing county
export const SelectedHexUI = () => {
  const realmId = useGameStore(state => state.player?.realm.id);
  const realmRank = useGameStore(
    state => state.player?.realm?.title?.rank || TitleRank.BARONY,
  );
  const holdings = useMapStore(state => state.holdings || []);
  const hex = useHexStore(state => state.selected);
  const select = useHexStore(state => state.selectHex);

  const updatePlayer = useGameStore(state => state.updatePlayer);
  const updateResources = useGameStore(state => state.updateResources);
  const updateRealms = useMapStore(state => state.updateRealms);
  const selectHolding = useHoldingStore(state => state.setSelectedHolding);

  const [showCreator, setShowCreator] = useState(false);

  const selection = useMemo(() => {
    if (!hex) return null;
    return offsetFromHex(hex);
  }, [hex]);

  const whatCanYouDo = useMemo(() => {
    if (!hex) return 'nothing';
    const holding = holdings.find(equalHexFilter(hex));

    if (holding && holding.type !== NONE) {
      // todo: check if holding is owned by player and add more options
      return 'enter';
    }

    if (holding && holding.type === NONE && holding.realmId === realmId) {
      return 'barony';
    }

    if (!holding) {
      return 'county';
    }

    return 'nothing';
  }, [hex, holdings, realmId]);

  const canCreate = useMemo(() => {
    if (whatCanYouDo === 'county' && realmRank !== TitleRank.BARONY)
      return true;
    if (whatCanYouDo === 'barony') return true;
    return false;
  }, [realmRank, whatCanYouDo]);

  const handleComplete = useCallback(() => {
    setShowCreator(false);
    select(null);
    updatePlayer();
    updateRealms();
    updateResources();
  }, [select, updateRealms, updatePlayer, updateResources]);

  const models = useMemo(
    () => [
      ...(canCreate
        ? [
            {
              label: `Found new ${whatCanYouDo}`,
              icon: 'pi pi-plus',
              onClick: () => setShowCreator(true),
            },
          ]
        : []),
      ...(whatCanYouDo === 'enter'
        ? [
            {
              label: 'Enter',
              icon: 'pi pi-arrow-right',
              onClick: () =>
                selectHolding(holdings.find(equalHexFilter(hex!))?.id!),
            },
          ]
        : []),
    ],
    [canCreate, hex, holdings, selectHolding, whatCanYouDo],
  );

  if (!hex || !models.length) return null;

  return (
    <>
      <BottomDock model={models} visible={!!hex} onHide={() => select(null)} />
      {canCreate && (
        <HoldingCreationDialog
          visible={showCreator}
          onClose={() => setShowCreator(false)}
          onComplete={handleComplete}
          selection={selection}
          realmRank={realmRank}
          type={whatCanYouDo}
        />
      )}
    </>
  );
};
