import { useGLTF } from '@react-three/drei';
import { GroupProps } from '@react-three/fiber';

import { FC, useMemo } from 'react';

import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: Record<string, THREE.Mesh>;
  materials: Record<string, THREE.Material>;
};

export const GlbModel: FC<GroupProps & { model: string }> = ({
  model,
  ...props
}) => {
  const { scene } = useGLTF(model) as GLTFResult;
  const cloned = useMemo(() => scene.clone(), [scene]);
  return <primitive object={cloned} {...props} />;
};
