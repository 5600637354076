import { HoldingType } from '../../types/models';
import { models } from '../models';

const holdingModels = {
  [HoldingType.TRIBE]: models.holding_tribe,
  [HoldingType.CASTLE]: models.holding_castle,
  [HoldingType.CITY]: models.holding_city,
  [HoldingType.TEMPLE]: models.holding_temple,
} as const;

export const getHoldingModel = (type: HoldingType, level: number) => {
  return holdingModels[type];
};
