import { Cost } from './cost';

export enum StructureHoldingType {
  tribe = 'TRIBAL',
  castle = 'CASTLE',
  city = 'CITY',
  temple = 'TEMPLE',
}

export enum StructureType {
  // holdings
  tribe = 'TRIBE',
  castle = 'CASTLE',
  city = 'CITY',
  temple = 'TEMPLE',
  // buildings
  barracks = 'BARRACKS',
  quarry = 'QUARRY',
  crops = 'CROPS',
  farm = 'FARM',
  lumbermill = 'LUMBERMILL',
  house = 'HOUSE',
}

export type RequirementValue = {
  level: number;
  count: number;
};

export class Structure {
  private _cost: Partial<Cost> = {};
  private _production: Partial<Cost> = {};
  private _capacity: Partial<Cost> = {};
  private _time: number = 5;
  private _requirements: Partial<Record<StructureType, RequirementValue>> = {};
  private _isHolding: boolean = false;
  private _requiredHoldings: StructureHoldingType[] = [];
  constructor(
    readonly id: StructureType,
    readonly level: number,
    readonly name: string,
  ) {}

  setCost(cost: Partial<Cost>) {
    this._cost = cost;
    return this;
  }

  setProduction(production: Partial<Cost>) {
    this._production = production;
    return this;
  }

  setCapacity(capacity: Partial<Cost>) {
    this._capacity = capacity;
    return this;
  }

  setBuildTime(time: number) {
    this._time = time;
    return this;
  }

  setRequirements(
    requirements: Partial<Record<StructureType, RequirementValue>>,
  ) {
    this._requirements = requirements;
    return this;
  }

  setRequiredHoldings(requiredHoldings: StructureHoldingType[]) {
    this._requiredHoldings = requiredHoldings;
    return this;
  }

  setIsHolding(isHolding: boolean) {
    this._isHolding = isHolding;
    return this;
  }

  get cost() {
    return this._cost;
  }

  get production() {
    return this._production;
  }

  get capacity() {
    return this._capacity;
  }

  get time() {
    return this._time;
  }

  get requirements() {
    return this._requirements;
  }

  get requiredHoldings() {
    return this._requiredHoldings;
  }

  get isHolding() {
    return this._isHolding;
  }
}
