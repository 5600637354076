import type { ColorRepresentation } from 'three';

export const WORLD_SIZE = 100;
export const URBAN_SIZE = 10;

export const TILE_BEVEL = {
  steps: 2,
  depth: 10,
  bevelEnabled: true,
  bevelThickness: 0.2,
  bevelSize: 0.5,
  bevelOffset: -0.5,
  bevelSegments: 2,
};

type IColor = { value: number; color: ColorRepresentation };

export const TILE_COLORS: Record<string, IColor> = {
  Water: {
    value: 0.16,
    color: '#6e8283',
  },
  Shore: {
    value: 0.01,
    color: '#a29d91',
  },
  Beach: {
    value: 0.04,
    color: '#a29d91',
    // color: '#b2a29a',
  },
  Shrub: {
    value: 0.1,
    color: '#777a6e',
  },
  Forest: {
    value: 0.25,
    color: '#545d54',
  },
  Stone: {
    value: 0.53,
    color: '#505050',
  },
  Snow: {
    value: 0.6,
    color: '#a7abab',
  },
};

export const TREE_COLORS: Record<string, ColorRepresentation[]> = {
  regular: [
    '#435743', //
    '#324b2f', //
    '#3d4822', //
  ],
  snow: [
    '#FEF5ED', //
  ],
};

export const TILE_GENERATIONS = {
  SeedWorld: 132456,
  Height: 1,
  Scale: 0.2,
  Detail: 0.5,
  Fuzziness: 0.25,
};

export const TILE_GENERATIONS_URBAN = {
  SeedWorld: 132456,
  Height: 0.3,
  Scale: 0.05,
  Detail: 0.5,
  Fuzziness: 0.25,
  HeightMultiplier: -10,
  HeightFlat: 0.5,
};

export const TILE_GENERATIONS_URBAN_OUTSKIRT = {
  SeedWorld: TILE_GENERATIONS_URBAN.SeedWorld,
  Height: 0.5,
  Scale: 0.1,
  Detail: 0.5,
  Fuzziness: 0.25,
  HeightMultiplier: 10,
};

export const TILE_WATER_COLOR: ColorRepresentation = '#5EBFB5';
export const TILE_COLOR_MAP: ColorRepresentation[] = [
  '#FCE0AE',
  '#F2B591',
  '#A7A267',
  '#656347',
  '#9AA7AD',
];
