import { FC } from 'react';

import { useAtomValue } from 'jotai';
import { AxesHelper, Color } from 'three';

import { disableCameraPan } from '../../store/atoms';
import { useSelectedHolding } from '../CityScene/hooks/useSelectedHolding';
import Lights from '../WorldScene/components/Lights';
import { UrbanTerrain } from './3_organism/UrbanTerrain';
import { WorldCameraControls } from '../../components/1_atoms/WorldCameraControls/WorldCameraControls';

const red = new Color('red');
const blue = new Color('blue');
const yellow = new Color('yellow');

export const UrbanScene: FC = () => {
  const disableCamera = useAtomValue(disableCameraPan);
  const holding = useSelectedHolding();

  return (
    <>
      <primitive object={new AxesHelper(100).setColors(red, yellow, blue)} />
      <Lights />
      <UrbanTerrain holding={holding} />
      <WorldCameraControls enabled={!disableCamera} />
    </>
  );
};
