import { useRef } from 'react';

import { useControls } from 'leva';
import { ColorRepresentation } from 'three';
import { DirectionalLight } from 'three/src/lights/DirectionalLight';

export default function Lights() {
  const ref = useRef<DirectionalLight>(null);

  const h = useControls('Hemisphere Light', {
    skyColor: 'grey',
    groundColor: 'black',
    intensity: 1,
  });

  const d = useControls('Directional Light', {
    color: '#808080',
    intensity: 1,
    shadowBias: 1,
    position: [-5, 14, -5],
    castShadow: true,
  });

  const f = useControls('Fog', {
    color: '#b6b6b6',
    near: 75,
    far: 150,
  });

  const b = useControls('Background', {
    color: '#b6b6b6',
  });

  return (
    <>
      <hemisphereLight
        args={[
          h.skyColor as ColorRepresentation,
          h.groundColor as ColorRepresentation,
          h.intensity,
        ]}
      />
      <directionalLight
        ref={ref}
        castShadow={d.castShadow}
        position={d.position}
        intensity={d.intensity}
        shadowBias={d.shadowBias}
        // shadow-mapSize={[1024, 1024]}
        // shadowMapWidth={1024}
        // shadowMapHeight={1024}
        color={d.color as ColorRepresentation}
      />
      <fog
        attach="fog"
        args={[f.color as ColorRepresentation, f.near, f.far]}
      />
      <color attach="background" args={[b.color as ColorRepresentation]} />
    </>
  );
}
