import axios from 'axios';

import { ShardType } from '../../types/login-server';

export const apiUri = process.env.REACT_APP_LOGIN_SERVER_URI;

export const ls = axios.create({
  baseURL: apiUri,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const getShardList = () =>
  ls.get<{ shards: ShardType[] }>('/shards').then(res => res.data.shards);
