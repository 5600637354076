import { Cost } from '@chiefdom/collections';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { formatNumber } from '../../../../utils/numbers';

type CostRendererProps = {
  cost: Partial<Cost>;
  available: Partial<Cost>;
};

export const CostRenderer: FC<CostRendererProps> = ({ cost, available }) => {
  const items = useMemo(
    () =>
      Object.keys(cost).map(key => {
        const costItem = (cost as any)[key];
        const availableItem = (available as any)[key] || 0;
        const isAvailable = availableItem >= costItem;
        return (
          <div
            key={key}
            className={'flex flex-row justify-start items-center gap-4 mb-2'}
          >
            <div className="w-1/2">{key}</div>
            <div
              className={clsx({
                'text-green-500': isAvailable,
                'text-red-500': !isAvailable,
              })}
            >
              {formatNumber(costItem)} ({formatNumber(availableItem)})
            </div>
          </div>
        );
      }),
    [cost, available],
  );

  return <>{items}</>;
};
