import { useMemo } from 'react';
import { useSelectedHolding } from '../scenes/CityScene/hooks/useSelectedHolding';
import { useGameStore } from '../store/game-store';

export const useIsRulerOfSelectedHolding = () => {
  const selectedHolding = useSelectedHolding();
  const characterId = useGameStore(state => state.player?.characterId);

  return useMemo(
    () =>
      selectedHolding?.title.rulerId &&
      characterId &&
      selectedHolding?.title.rulerId === characterId,
    [selectedHolding?.title?.rulerId, characterId],
  );
};
