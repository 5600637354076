import { Color, MathUtils } from 'three';
import { TILE_COLORS } from '../../constants/map';

export const getColor = () => {
  return (height: number) => {
    const color = (() => {
      if (height <= TILE_COLORS.Water.value) {
        return new Color(TILE_COLORS.Water.color);
      } else if (height <= TILE_COLORS.Water.value + TILE_COLORS.Shore.value) {
        return new Color(TILE_COLORS.Shore.color);
      } else if (height <= TILE_COLORS.Water.value + TILE_COLORS.Beach.value) {
        return new Color(TILE_COLORS.Beach.color);
      } else if (height <= TILE_COLORS.Water.value + TILE_COLORS.Shrub.value) {
        return new Color(TILE_COLORS.Shrub.color);
      } else if (height <= TILE_COLORS.Water.value + TILE_COLORS.Forest.value) {
        return new Color(TILE_COLORS.Forest.color);
      } else if (height <= TILE_COLORS.Water.value + TILE_COLORS.Stone.value) {
        return new Color(TILE_COLORS.Stone.color);
      } else {
        return new Color(TILE_COLORS.Snow.color);
      }
    })();

    let a = {
      h: 0,
      s: 1,
      l: 1,
    };
    const hsl = color.getHSL(a);
    color.setHSL(
      hsl.h,
      hsl.s * 1.7,
      hsl.l *
        (height <= TILE_COLORS.Water.value
          ? MathUtils.mapLinear(
              Math.pow(1 - (TILE_COLORS.Water.value - height) * 1.3, 6),
              0,
              1,
              0,
              1.4,
            )
          : 1),
    );

    return color;
  };
};
