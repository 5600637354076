import { WORLD_SIZE } from '../constants/map';

export type WaterSettings = {
  offset: number;
  width: number;
  height: number;
  widthSegment: number;
  heightSegment: number;
  transparent: boolean;
};

export const WATER_WORLD = {
  offset: 11.6,
  width: WORLD_SIZE * 5,
  height: WORLD_SIZE * 5,
  widthSegment: WORLD_SIZE * 10,
  heightSegment: WORLD_SIZE * 10,
  transparent: false,
};

export const WATER_URBAN = {
  offset: 2.0,
  width: 200,
  height: 200,
  widthSegment: 200 * 2,
  heightSegment: 200 * 2,
  transparent: true,
};
