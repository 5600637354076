import { FC, useCallback } from 'react';
import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import { ICoa } from '../../../../types/models';
import { CoatOfArms } from '../CoatOfArms/CoatOfArms';

export type CoaFormState = ICoa;

export type CoaEditorProps = {
  value: CoaFormState;
  onChange: (coa: CoaFormState) => void;
};

export const CoaEditor: FC<CoaEditorProps> = ({ value, onChange }) => {
  const handleColorChange = useCallback(
    (key: keyof typeof value) => (e: ColorPickerChangeEvent) => {
      onChange({
        ...value,
        [key]: e.value,
      });
    },
    [onChange, value],
  );

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="p-4 mb-8">
        <CoatOfArms coa={value} className="h-48" />
      </div>
      <div className="flex flex-row gap-8">
        <ColorPicker
          value={value.primaryColor}
          onChange={handleColorChange('primaryColor')}
        />
        <ColorPicker
          value={value.secondaryColor}
          onChange={handleColorChange('secondaryColor')}
        />
      </div>
    </div>
  );
};
