import { Button } from 'primereact/button';
import { FC, useState } from 'react';
import { ICoa } from '../../../../../types/models';
import { CoaEditor } from '../../../1_atoms/CoaEditor/CoaEditor';

type CaoFormState = ICoa;

type CoatOfArmsCreatorProps = {
  value: CaoFormState;
  onCancel: () => void;
  onSubmit: (coa: CaoFormState) => void;
};

export const CoatOfArmsCreator: FC<CoatOfArmsCreatorProps> = ({
  value,
  onSubmit,
  onCancel,
}) => {
  const [form, setForm] = useState(value);
  return (
    <>
      <div className="w-full flex flex-row justify-center items-center bg-gray-300 mb-4 p-2">
        <div>Coat Of Arms</div>
      </div>
      <div className="flex justify-center items-start gap-2">
        <CoaEditor value={form} onChange={setForm} />
      </div>
      <div className="bg-gray-300 py-2 px-4 mt-4 flex flex-row justify-between gap-8 items-center">
        <Button
          label="Cancel"
          onClick={() => onCancel()}
          icon="pi pi-arrow-left"
          size="small"
        />
        <Button
          label="Confirm"
          onClick={() => onSubmit(form)}
          icon="pi pi-check"
          size="small"
        />
      </div>
    </>
  );
};
