import { Text } from '@react-three/drei';

import { FC } from 'react';

import { degToRad } from 'three/src/math/MathUtils';

import { getHoldingModel } from '../../../../../data/models/holdings';
import { IHolding } from '../../../../../types/models';
import { Coa } from '../../../../components/1_atoms/Coa/Coa';
import { GlbModel } from '../../../../components/GlbModel';
import { WORLD_LAYOUT, hexToScreen } from '@chiefdom/shared';
import { MeshBasicMaterial } from 'three';
import { getWorldMapTile } from '../../../../../utils/map/hex';

const textMaterial = new MeshBasicMaterial({
  color: 0xffff00,
});

type HoldingLayoutProps = {
  holding: IHolding;
  capital?: boolean;
  onClick?: (id: number) => void;
};

export const HoldingLayout: FC<HoldingLayoutProps> = ({
  holding,
  capital,
  onClick,
}) => {
  const vecTranslatedTileCoords = hexToScreen(WORLD_LAYOUT, holding);
  const translatedTileHeight = (getWorldMapTile(holding)?.height || 0) + 10.2;

  return (
    <group
      position={[
        vecTranslatedTileCoords.x,
        translatedTileHeight,
        vecTranslatedTileCoords.y,
      ]}
    >
      <group scale={0.5} position={[0, 0, 0]}>
        <mesh
          position={[0, 0, 0]}
          rotation={[0, 0, 0]}
          receiveShadow
          castShadow
        >
          <GlbModel model={getHoldingModel(holding.type, holding.level)} />
        </mesh>
      </group>

      <HoldingName holding={holding} isCapital={capital} />
    </group>
  );
};

type HoldingNameProps = {
  holding: IHolding;
  isCapital?: boolean;
};

const HoldingName: FC<HoldingNameProps> = ({ holding, isCapital }) => (
  <group
    position={[0, 0.1, 0.7]}
    rotation={[degToRad(90), degToRad(180), degToRad(180)]}
    renderOrder={4}
  >
    <Text fontSize={0.2} material={textMaterial}>
      {holding.name}
    </Text>
    {holding.title && (
      <group position={[0, -0.1, -0.1]}>
        <Coa
          coa={holding.title.coa}
          scale={isCapital ? 0.1 : 0.05}
          rank={holding.title.rank}
        />
      </group>
    )}
  </group>
);
