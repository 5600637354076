import {
  createHexMap,
  hex,
  HEX_ORIENTATION,
  hexToScreen,
  IHex,
  layout,
  offsetFromHex,
  ORIGIN_ZERO,
} from '@chiefdom/shared';

import * as THREE from 'three';
import { Vector2, Vector3 } from 'three';

import { getNoiseWorldByCoords } from '../generation/tile-coords';
import { TILE_COLORS, URBAN_SIZE, WORLD_SIZE } from '../../constants/map';
import { getColor } from '../generation/color';

export type HexSetup = {
  hexes: HexTile[];
};

export type HexTile = {
  id: number;
  hex: IHex;
  positionHex: Vector3;
  positionHexScreen: Vector2;
  color: THREE.Color;
  height: number;
  noise: number;
};

export type HexAssetSetup = {
  normalTrees: HexAsset[];
  snowTrees: HexAsset[];
  grass: HexAsset[];
  rocks: HexAsset[];
  rocksSand: HexAsset[];
};

type HexAsset = {
  height: number;
  positionHex: Vector3;
  positionHexScreen: Vector2;
  rngFactor: number;
  assetScale: number;
};

export const HEX_URBAN_MAP_CHUNK = createHexMap(URBAN_SIZE);
export const HEX_WORLD_MAP = createHexMap(WORLD_SIZE);

export const generateWorldMap = () => {
  const hexMap = HEX_WORLD_MAP;
  const hexTiles: HexTile[] = [];
  const color = getColor();
  let id = 0;

  for (let i = 0; i < hexMap.length; i++) {
    const h = hex(hexMap[i].q, hexMap[i].r, hexMap[i].s);
    const o = offsetFromHex(h);

    const hScreenCoords = hexToScreen(
      layout(HEX_ORIENTATION, 1, ORIGIN_ZERO),
      h,
    );

    const hNoise = getNoiseWorldByCoords(o.x, o.y);
    const hColor = color(hNoise);
    const hHeight = hNoise * 10;

    if (hNoise < TILE_COLORS.Water.value) continue;

    hexTiles.push({
      id: id,
      hex: h,
      positionHex: new Vector3(h.q, h.r, h.s),
      positionHexScreen: new Vector2(hScreenCoords.x, hScreenCoords.y),
      color: hColor,
      height: hHeight,
      noise: hNoise,
    });
    id++;
  }

  const hexSetup: HexSetup = { hexes: hexTiles };
  return hexSetup;
};

export const generateWorldAssets = (hexSetup: HexSetup) => {
  const hexes = hexSetup.hexes;
  const assetSetup: HexAssetSetup = {
    normalTrees: [],
    snowTrees: [],
    grass: [],
    rocks: [],
    rocksSand: [],
  };

  hexes.forEach(hex => {
    if (
      hex.noise > TILE_COLORS.Shrub.value + TILE_COLORS.Water.value &&
      hex.noise < TILE_COLORS.Forest.value + TILE_COLORS.Water.value
    ) {
      if (Math.random() > 0.3) {
        const asset: HexAsset = {
          height: hex.height,
          positionHex: hex.positionHex,
          positionHexScreen: hex.positionHexScreen,
          rngFactor: 0.4,
          assetScale: 1,
        };

        assetSetup.normalTrees.push(asset);
        assetSetup.normalTrees.push(asset);
        assetSetup.normalTrees.push(asset);
        assetSetup.normalTrees.push(asset);

        if (Math.random() > 0.5) {
          const asset: HexAsset = {
            height: hex.height,
            positionHex: hex.positionHex,
            positionHexScreen: hex.positionHexScreen,
            rngFactor: 0.2,
            assetScale: 0.8,
          };
          assetSetup.normalTrees.push(asset);
        }

        if (Math.random() > 0.5) {
          const asset: HexAsset = {
            height: hex.height,
            positionHex: hex.positionHex,
            positionHexScreen: hex.positionHexScreen,
            rngFactor: 0.2,
            assetScale: 0.6,
          };
          assetSetup.normalTrees.push(asset);
        }
      }
    }
    if (
      hex.noise > TILE_COLORS.Beach.value + TILE_COLORS.Water.value &&
      hex.noise < TILE_COLORS.Forest.value + TILE_COLORS.Water.value
    ) {
      if (Math.random() < 0.7) {
        const asset: HexAsset = {
          height: hex.height,
          positionHex: hex.positionHex,
          positionHexScreen: hex.positionHexScreen,
          rngFactor: 0.4,
          assetScale: 1,
        };

        assetSetup.grass.push(asset);
      }

      if (Math.random() > 0.6) {
        const asset: HexAsset = {
          height: hex.height,
          positionHex: hex.positionHex,
          positionHexScreen: hex.positionHexScreen,
          rngFactor: 0.4,
          assetScale: 1,
        };
        assetSetup.grass.push(asset);
      }

      if (Math.random() > 0.5) {
        const asset: HexAsset = {
          height: hex.height,
          positionHex: hex.positionHex,
          positionHexScreen: hex.positionHexScreen,
          rngFactor: 0.4,
          assetScale: 1,
        };
        assetSetup.grass.push(asset);
      }
    }
    if (hex.noise >= TILE_COLORS.Forest.value + TILE_COLORS.Water.value) {
      if (Math.random() < 0.4) {
        const asset: HexAsset = {
          height: hex.height,
          positionHex: hex.positionHex,
          positionHexScreen: hex.positionHexScreen,
          rngFactor: 0.2,
          assetScale: 1.2,
        };

        assetSetup.rocks.push(asset);
      }
    }
    if (
      hex.noise > TILE_COLORS.Shore.value + TILE_COLORS.Water.value &&
      hex.noise < TILE_COLORS.Beach.value + TILE_COLORS.Water.value
    ) {
      if (Math.random() < 0.3) {
        const asset: HexAsset = {
          height: hex.height,
          positionHex: hex.positionHex,
          positionHexScreen: hex.positionHexScreen,
          rngFactor: 0.3,
          assetScale: 1,
        };

        assetSetup.rocksSand.push(asset);
      }
    }
  });
  return assetSetup;
};
