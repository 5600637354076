import { Button, ButtonProps } from 'primereact/button';
import { FC } from 'react';
import clsx from 'clsx';

type RightDockProps = {
  model: ButtonProps[];
};

export const RightDock: FC<RightDockProps> = ({ model }) => {
  return (
    <div className="absolute right-4 bottom-4 top-4 flex flex-col items-center justify-center gap-4">
      {model.map(({ className, ...props }, index) => (
        <Button
          key={index}
          {...props}
          className={clsx(className, 'interactive w-32')}
        />
      ))}
    </div>
  );
};
