import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PRODUCTION } from '../../../constants/general';

type DisableMapAssets = {
  trees: boolean;
  rocks: boolean;
  grass: boolean;
};

type SettingsStore = {
  devControls: boolean;
  devStats: boolean;
  disableMapAssets: DisableMapAssets;
  setDevControls: (value: boolean) => void;
  setDevStats: (value: boolean) => void;
  setDisableMapAssets: (key: keyof DisableMapAssets, value: boolean) => void;
};

export const useSettingsStore = create(
  persist(
    immer<SettingsStore>(set => ({
      devControls: !PRODUCTION,
      devStats: !PRODUCTION,
      disableMapAssets: {
        trees: false,
        rocks: false,
        grass: true,
      },
      setDevControls: value => set(state => void (state.devControls = value)),
      setDevStats: value => set(state => void (state.devStats = value)),
      setDisableMapAssets: (key, value) =>
        set(state => void (state.disableMapAssets[key] = value)),
    })),
    {
      name: 'player-settings-store',
    },
  ),
);
