import { Canvas } from '@react-three/fiber';

import { PCFSoftShadowMap, sRGBEncoding } from 'three';

import '../data/preloader';
import { RootUITemplate } from './components/4_templates/RootUITemplate/RootUITemplate';
import { UrbanScene } from './scenes/UrbanScene/UrbanScene';
import { WorldScene } from './scenes/WorldScene/WorldScene';
import { useHoldingStore } from './store/game/holding-store';
import { CameraSeeker } from './components/2_molecules/CameraSeeker/CameraSeeker';
import { ModalProvider } from './components/0_meta/ModalProvider/ModalProvider';
import { CAMERA_POSITION, CAMERA_ROTATION } from '../constants/general';

export default function App() {
  const selectedHolding = useHoldingStore(state => state.selectedHolding);
  return (
    <>
      <Canvas
        shadows={{ type: PCFSoftShadowMap }}
        // flat={true}
        gl={{
          antialias: true,
          toneMappingExposure: 0.5,
          powerPreference: 'high-performance',
          outputEncoding: sRGBEncoding,
        }}
        camera={{
          position: CAMERA_POSITION,
          rotation: CAMERA_ROTATION,
          far: 200,
          fov: 60,
        }}
      >
        {selectedHolding === 0 ? <WorldScene /> : <UrbanScene />}
        <CameraSeeker />
      </Canvas>
      <RootUITemplate selectedHoldingId={selectedHolding} />
      <ModalProvider />
    </>
  );
}
