import type { IHex, IPoint } from './types';

export class Hex implements IHex {
  readonly q: number;
  readonly r: number;
  readonly s: number;

  constructor({ q, r, s }: IHex) {
    this.q = q;
    this.r = r;
    this.s = s;
    if (Math.round(this.q + this.r + this.s) !== 0) {
      throw new Error('q + r + s must be 0');
    }
  }

  static create(params: IHex) {
    if (params instanceof this) {
      return params;
    }
    return new this(params);
  }

  toArray(): [number, number, number] {
    return [this.q, this.r, this.s];
  }
}

export class Point implements IPoint {
  readonly x: number;
  readonly y: number;
  constructor({ x, y }: IPoint) {
    this.x = x;
    this.y = y;
  }

  static create(params: IPoint) {
    if (params instanceof this) {
      return params;
    }
    return new this(params);
  }

  toArray(): [number, number] {
    return [this.x, this.y];
  }

  toArray3(axis: 'Y' | 'Z' = 'Y'): [number, number, number] {
    return [this.x, axis === 'Z' ? this.y : 0, axis === 'Y' ? this.y : 0];
  }
}

export class Offset extends Point {}
