export const test = () => {
  try {
    console.log(process.env);
    const commit = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF!;
    if (
      localStorage.getItem('commit') !== commit &&
      process.env.NODE_ENV === 'production'
    ) {
      localStorage.clear();
      localStorage.setItem('commit', commit);
    }
  } catch (e) {
    console.error(e);
  }
};

test();
