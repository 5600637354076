import { useGLTF } from '@react-three/drei';

import { FC, useEffect, useMemo, useRef } from 'react';

import * as THREE from 'three';
import { InstancedMesh, MathUtils } from 'three';
import { GLTF } from 'three-stdlib';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';

import { models } from '../../../../../data/models';
import { worldMapAssetsSetup } from '../../../../../config/map/setup';

const tempV4 = new THREE.Object3D();
type GLTFResult = GLTF & {
  nodes: Record<string, THREE.Mesh>;
  materials: Record<string, THREE.Material>;
};

const InstancedRocks: FC = () => {
  const { nodes, materials } = useGLTF(models.environment_rock_3) as GLTFResult;
  const ref = useRef<InstancedMesh>(null);
  const mergedGeom = useMemo(() => {
    return mergeBufferGeometries([
      nodes.Mesh_platform_stone.geometry,
      nodes.Mesh_platform_stone_1.geometry,
    ]);
  }, [nodes]);

  useEffect(() => {
    if (!ref.current) return;
    const mesh = ref.current;

    worldMapAssetsSetup.rocksSand.forEach((asset, i) => {
      tempV4.position.x =
        asset.positionHexScreen.x +
        MathUtils.randFloat(-asset.rngFactor, asset.rngFactor);
      tempV4.position.z =
        asset.positionHexScreen.y +
        MathUtils.randFloat(-asset.rngFactor, asset.rngFactor);
      tempV4.position.y = asset.height + 10.2;
      tempV4.scale.set(asset.assetScale, asset.assetScale, asset.assetScale);
      tempV4.rotation.set(0, Math.floor(Math.random() * 359), 0);

      tempV4.updateMatrix();
      mesh.setMatrixAt(i, tempV4.matrix);
    });
    mesh.instanceMatrix.needsUpdate = true;
  }, [ref]);

  return (
    <instancedMesh
      ref={ref}
      castShadow
      receiveShadow
      args={[mergedGeom, materials.stone, worldMapAssetsSetup.rocksSand.length]}
      frustumCulled={false}
    />
  );
};

export default InstancedRocks;
