import { FC } from 'react';

import { AxesHelper, Color } from 'three';
import { WorldCameraControls } from '../../components/1_atoms/WorldCameraControls/WorldCameraControls';

import Lights from './components/Lights';
import { WorldTerrain } from './components/WorldTerrain';
import { T3SelectedHexIndicator } from './components/1_atoms/T3SelectedHexIndicator';

const red = new Color('red');
const blue = new Color('blue');
const yellow = new Color('yellow');

export const WorldScene: FC = () => (
  <>
    <Lights />
    <primitive object={new AxesHelper(100).setColors(red, yellow, blue)} />
    <WorldTerrain />
    <T3SelectedHexIndicator />
    <WorldCameraControls />
  </>
);
