import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export enum MenuType {
  None,
  Realm,
  Council,
  Military,
  Settings,
}

type ControlStore = {
  item: MenuType;
  set: (item: MenuType) => void;
};

export const useMenuStore = create(
  immer<ControlStore>(set => ({
    item: MenuType.None,
    set: item => set(state => void (state.item = item)),
  })),
);

export const getMenuTab = () => useMenuStore.getState().item;
export const setMenuTab = (item: MenuType) => useMenuStore.getState().set(item);
export const closeMenuTab = () => useMenuStore.getState().set(MenuType.None);
