import * as React from 'react';
import { FC } from 'react';

import { WATER_URBAN } from '../../../../config/water-config';
import { IHolding } from '../../../../types/models';
import Water from '../../WorldScene/components/3_organism/Water';
import { OutskirtGroup } from './OutskirtGroup';
import { UrbanTiles } from './UrbanTiles';

type UrbanTerrainProps = {
  holding: IHolding | undefined;
};

export const UrbanTerrain: FC<UrbanTerrainProps> = ({ holding }) => {
  return (
    <group>
      <UrbanTiles holding={holding} />
      <OutskirtGroup holding={holding} />
      <Water settings={WATER_URBAN} />
    </group>
  );
};
