import { Compass } from './components/Compass';
import { RightDockOnMap } from './components/RightDockOnMap';
import { SelectedHexUI } from './components/SelectedHexUI';
import { SelectedHolding } from './components/SelectedHoldingHud';

export const WorldMapUI = () => {
  // const selectHolding = useHoldingStore(state => state.setSelectedHolding);
  // const holding = usePlayerCapital();

  // const dockItems = useMemo(
  //   () => [
  //     {
  //       label: 'My City',
  //       icon: 'pi pi-home',
  //       onClick: () => selectHolding(holding?.id ?? 0),
  //     },
  //   ],
  //   [holding?.id, selectHolding],
  // );

  return (
    <>
      <SelectedHolding />
      <RightDockOnMap />
      <SelectedHexUI />
      <Compass />
    </>
  );
};
