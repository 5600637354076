import { hex, hexToScreen, URBAN_LAYOUT } from '@chiefdom/shared';

import * as React from 'react';
import { FC } from 'react';

import { FrontSide } from 'three';

import { TILE_GENERATIONS_URBAN } from '../../../../constants/map';
import { IHolding } from '../../../../types/models';
import { getNoiseUrbanByCoords } from '../../../../utils/generation/tile-coords';
import { HEX_URBAN_MAP_CHUNK } from '../../../../utils/map/generator';
import { getWorldMapTile } from '../../../../utils/map/hex';
import BeveledHexagonGeometry from '../../WorldScene/components/1_atoms/BeveledHexagonGeometry';

type UrbanTilesProps = {
  holding?: IHolding;
};

export const UrbanTiles: FC<UrbanTilesProps> = ({ holding }) => {
  if (!holding) return <></>;
  const hexPos = hex(holding.q, holding.r, holding.s);
  const hexTile = getWorldMapTile(hexPos);
  if (!hexTile) return <></>;

  return (
    <group rotation={[Math.PI / 2, 0, 0]}>
      {HEX_URBAN_MAP_CHUNK.map((hex, i) => {
        const coords = hexToScreen(URBAN_LAYOUT, HEX_URBAN_MAP_CHUNK[i]);
        return (
          <mesh
            key={i}
            position={[
              coords.x,
              coords.y,
              getNoiseUrbanByCoords(coords.x, coords.y) -
                hexTile.height -
                TILE_GENERATIONS_URBAN.HeightFlat,
            ]}
          >
            <BeveledHexagonGeometry attach={'geometry'} layout={URBAN_LAYOUT} />
            <meshPhongMaterial
              attach={'material'}
              color={hexTile.color}
              shadowSide={FrontSide} //
              side={FrontSide} //
            />
          </mesh>
        );
      })}
    </group>
  );
};
