import { Button } from 'primereact/button';
import { TooltipHTMLAttributes } from 'primereact/tooltip/tooltipoptions';
import { FC, useCallback } from 'react';

type SkillProps = {
  name: string;
  value: number;
  onChange: (value: number) => void;
};

export const Skill: FC<SkillProps & TooltipHTMLAttributes> = ({
  name,
  value,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (val: number) => () => {
      onChange(val);
    },
    [onChange],
  );

  return (
    <div className="flex flex-row justify-between items-center gap-2 p-2">
      <div className="flex-grow skill">
        <span className="skill" {...props}>
          {name}
        </span>
      </div>
      <div className="flex flex-row justify-end items-center gap-2 flex-grow-0">
        <div>{value}</div>
        <div className="flex flex-row p-buttonset">
          <Button icon="pi pi-minus" onClick={handleChange(-1)} size="small" />
          <Button icon="pi pi-plus" onClick={handleChange(1)} size="small" />
        </div>
      </div>
    </div>
  );
};
