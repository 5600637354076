import { FC } from 'react';
import { WorldMapUI } from '../../3_organism/WorldMapUI/WorldMapUI';
import { CityMapUI } from '../../3_organism/CityMapUI/CityMapUI';
import { ChatSystem } from '../../3_organism/ChatSystem/ChatSystem';
import { PlayerDockUI } from '../../3_organism/PlayerDockUI/PlayerDockUI';

type RootUITemplateProps = {
  selectedHoldingId: number;
};

export const RootUITemplate: FC<RootUITemplateProps> = ({
  selectedHoldingId,
}) => {
  return (
    <div className="absolute z-10 inset-0 select-none non-interactive">
      {selectedHoldingId === 0 ? <WorldMapUI /> : <CityMapUI />}
      <ChatSystem />
      <PlayerDockUI />
    </div>
  );
};
