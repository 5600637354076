import { useMemo } from 'react';

import * as THREE from 'three';

import { hex, polygonCorners, WORLD_LAYOUT } from '@chiefdom/shared';
import { TILE_BEVEL } from '../../../../../constants/map';

export function BeveledHexagonGeometry({ layout = WORLD_LAYOUT, ...rest }) {
  const shape = useMemo(() => {
    const shape = new THREE.Shape();
    const points = polygonCorners(layout, hex(0, 0, 0));
    shape.moveTo(points[0].x, points[0].y);

    for (let i = 1; i < points.length; i++) {
      shape.lineTo(points[i].x, points[i].y);
    }
    return shape;
  }, [layout]);

  const settings = useMemo(() => TILE_BEVEL, []);

  return <extrudeGeometry args={[shape, settings]} {...rest} />;
}

export default BeveledHexagonGeometry;
