import axios from 'axios';

import { auth } from '../../config/firebase';
import { httpErrorEvents } from '../../events/http-error';
import { useShardStore } from '../../app/store/shard-store';

let _idToken: string | null = null;

export const getIdTokenStatic = () => _idToken;

export const getIdToken = async () => {
  _idToken = await auth.currentUser?.getIdToken()!;
  return _idToken;
};

export const getGsEndpoint = () => useShardStore.getState().shard?.url;

export const gs = axios.create({
  baseURL: getGsEndpoint(),
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

gs.interceptors.request.use(
  async config => {
    const token = await getIdToken();
    (config as any).headers['Authorization'] = `Bearer ${token}`;
    config.baseURL = getGsEndpoint();
    return config;
  },
  error => Promise.reject(error),
);

gs.interceptors.response.use(
  success => success,
  async error => {
    if (error.response.status === 401) {
      await auth.signOut();
      httpErrorEvents.push('Your session has expired. Please log in again.');
    } else if (
      String(error.response?.status).startsWith('4') &&
      error.response.status !== 404
    ) {
      httpErrorEvents.push(error.response.data.errorMessage);
    }

    return Promise.reject(error);
  },
);
