import { FC } from 'react';
import { Sex } from '../../../../types/models';

type CharacterPortraitProps = {
  sex: Sex;
};

export const CharacterPortrait: FC<CharacterPortraitProps> = ({ sex }) => {
  return (
    <div>
      <img
        src={sex === Sex.MALE ? '/images/king.png' : '/images/queen.png'}
        className="rounded-full w-24 h-24 object-scale-down"
        alt="Character"
      />
    </div>
  );
};
