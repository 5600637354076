import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { Mesh } from 'three';
import { useHexStore } from '../../../../store/game/hex-store';
import { getWorldMapTile } from '../../../../../utils/map/hex';

export const T3SelectedHexIndicator = () => {
  const selected = useHexStore(state => state.selected);
  const changed = useRef(false);

  const ref = useRef<Mesh>();

  useFrame(({ clock }) => {
    if (ref.current && selected) {
      ref.current.rotation.y = clock.getElapsedTime();
      ref.current.rotation.z = clock.getElapsedTime();

      const tile = getWorldMapTile(selected);
      const h = tile?.positionHexScreen!;
      ref.current.position.set(h.x, (tile?.height || 0) + 10.5, h.y);
      changed.current = true;
    }

    if (ref.current && !selected && changed.current) {
      ref.current.position.set(0, 0, 0);
      changed.current = false;
    }
  });

  return (
    <mesh ref={ref as any} renderOrder={2}>
      <boxGeometry args={[1, 1, 1]} />
      <meshBasicMaterial color="red" opacity={0.5} transparent={true} />
    </mesh>
  );
};
