import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBwOr5S73LvlFh1lwC5U4QjfNtAZS0aOi8',
  authDomain: 'chiefdom.firebaseapp.com',
  projectId: 'chiefdom',
  storageBucket: 'chiefdom.appspot.com',
  messagingSenderId: '921971383794',
  appId: '1:921971383794:web:998c6f08afc2fa2edd9f6b',
  measurementId: 'G-CJSLF6S5KB',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };
