import { useCallback, useEffect, useMemo, useRef } from 'react';
import { SpeedDial } from 'primereact/speeddial';

import { useBuilderStore } from '../../../../store/game/builder-store';
import { useHoldingStore } from '../../../../store/game/holding-store';
import { gs } from '../../../../../utils/clients/gs';
import { BottomDock } from '../../../2_molecules/BottomDock/BottomDock';
import { ButtonProps } from 'primereact/button';
import { useGameStore } from '../../../../store/game-store';

export const BuildingEditor = () => {
  const ref = useRef<SpeedDial>(null);
  const holding = useHoldingStore(state => state.selectedHolding);
  const updateBuildsin = useHoldingStore(state => state.setHoldings);
  const updateResources = useGameStore(state => state.updateResources);
  const selected = useBuilderStore(state => state.selected);
  const clear = useBuilderStore(state => state.resetEdits);
  const rotate = useBuilderStore(state => state.rotateSelected);
  const select = useBuilderStore(state => state.selectBuilding);
  const edits = useBuilderStore(state => state.edits);

  const handleCancel = useCallback(() => {
    clear();
    select(null);
  }, [clear, select]);

  const handleConfirm = useCallback(() => {
    gs.put(`/holdings/${holding}/structures`, {
      edits,
    }).then(() => {
      select(null);
      gs.get(`/holdings/${holding}`).then(({ data }) => {
        updateBuildsin([data]);
        updateResources();
        clear();
      });
    });
  }, [clear, edits, holding, select, updateBuildsin, updateResources]);

  const handleRotate = useCallback(() => {
    rotate();
  }, [rotate]);

  const items: ButtonProps[] = useMemo(
    () => [
      {
        label: 'Accept',
        icon: 'pi pi-check',
        onClick: () => handleConfirm(),
      },
      {
        label: 'Rotate',
        icon: 'pi pi-replay',
        onClick: () => handleRotate(),
      },
    ],
    [handleConfirm, handleRotate],
  );

  useEffect(() => {
    if (selected) {
      ref.current?.show();
    } else {
      ref.current?.hide();
    }
  }, [selected]);

  return (
    <BottomDock
      model={items}
      visible={!!selected}
      onHide={() => handleCancel()}
    />
  );
};
