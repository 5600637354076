import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { Tooltip } from 'primereact/tooltip';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Sex } from '../../../../../types/models';
import { CharacterPortrait } from '../../../1_atoms/CharacterPortrait/CharacterPortrait';
import { Skill } from './Skill';
import {
  getRandomDynastyName,
  getRandomFemaleName,
  getRandomMaleName,
} from '@chiefdom/shared';

const MAX_SKILL = 50;

export type CharacterCreatorFormState = {
  firstName: string;
  sex: Sex;
  houseName: string;
  diplomacy: number;
  martial: number;
  stewardship: number;
  intrigue: number;
  learning: number;
};

type CharacterCreatorProps = {
  value: CharacterCreatorFormState;
  onContinue: (form: CharacterCreatorFormState) => void;
};

export const CharacterCreator: FC<CharacterCreatorProps> = ({
  value,
  onContinue,
}) => {
  const [form, setForm] = useState<CharacterCreatorFormState>(value);

  const skillPointsUsed = useMemo(() => {
    return (
      form.diplomacy +
      form.martial +
      form.stewardship +
      form.intrigue +
      form.learning
    );
  }, [form]);

  const handleFormChange = useCallback(
    (key: keyof CharacterCreatorFormState) =>
      (e: ChangeEvent<HTMLInputElement>) =>
        setForm(p => ({ ...p, [key]: e.target.value })),
    [],
  );

  const handleSexChange = useCallback(
    (e: RadioButtonChangeEvent) =>
      setForm(p => ({ ...p, sex: e.target.value })),
    [],
  );

  const handleSkillChange = useCallback(
    (skill: string) => (value: number) => {
      setForm(p => {
        const current =
          p.diplomacy +
          p.martial +
          p.stewardship +
          p.intrigue +
          p.learning +
          value;
        if (current > MAX_SKILL) {
          return p;
        }

        const val = Math.max(Math.min((p as any)[skill] + value, 100), 0);
        return { ...p, [skill]: val };
      });
    },
    [],
  );

  const handleRandomName = useCallback(() => {
    setForm(p => ({
      ...p,
      firstName:
        p.sex === Sex.MALE ? getRandomMaleName() : getRandomFemaleName(),
    }));
  }, []);

  const handleRandomHouse = useCallback(() => {
    setForm(p => ({
      ...p,
      houseName: getRandomDynastyName(),
    }));
  }, []);

  return (
    <>
      <div className="w-full flex flex-row justify-center items-center bg-gray-300 mb-4 p-2">
        <div>
          {form.firstName} of {form.houseName}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-start gap-2">
        <div
          id="character"
          className="bg-gray-300 w-full flex flex-col items-center px-2 py-4 sm:flex-grow"
        >
          <div id="avatar" className="mb-4">
            <CharacterPortrait sex={form.sex} />
          </div>
          <div className="mb-3">
            <label htmlFor="firstName" className="mb-2 text-sm block">
              First Name
            </label>
            <div className="flex flex-row justify-between gap-2">
              <InputText
                id="firstName"
                name="firstName"
                value={form.firstName}
                onChange={handleFormChange('firstName')}
                placeholder="First Name"
              />
              <Button icon="pi pi-replay" onClick={handleRandomName} />
            </div>
          </div>
          <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="male"
                name="sex"
                value="MALE"
                onChange={handleSexChange}
                checked={form.sex === 'MALE'}
              />
              <label htmlFor="male" className="ml-2">
                Male
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="female"
                name="female"
                value="FEMALE"
                onChange={handleSexChange}
                checked={form.sex === 'FEMALE'}
              />
              <label htmlFor="female" className="ml-2">
                Female
              </label>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="houseName" className="mb-2 text-sm block">
              House
            </label>
            <div className="flex flex-row justify-between gap-2">
              <InputText
                id="houseName"
                name="houseName"
                value={form.houseName}
                onChange={handleFormChange('houseName')}
                placeholder="House Name"
              />
              <Button icon="pi pi-replay" onClick={handleRandomHouse} />
            </div>
          </div>
        </div>
        <div
          id="attributes"
          className="bg-gray-300 w-full px-2 py-4 sm:flex-grow"
        >
          <Tooltip target=".skill" position="top" />
          <Skill
            name="Diplomacy"
            value={form.diplomacy}
            onChange={handleSkillChange('diplomacy')}
            data-pr-tooltip="Diplomacy is the ability to negotiate with other characters."
          />
          <Skill
            name="Martial"
            value={form.martial}
            onChange={handleSkillChange('martial')}
            data-pr-tooltip="Martial is the ability to lead armies and fight in battles."
          />
          <Skill
            name="Stewardship"
            value={form.stewardship}
            onChange={handleSkillChange('stewardship')}
            data-pr-tooltip="Stewardship is the ability to manage your holdings and your vassals."
          />
          <Skill
            name="Intrigue"
            value={form.intrigue}
            onChange={handleSkillChange('intrigue')}
            data-pr-tooltip="Intrigue is the ability to manipulate and deceive other characters."
          />
          <Skill
            name="Learning"
            value={form.learning}
            onChange={handleSkillChange('learning')}
            data-pr-tooltip="Learning is the ability to learn new skills and improve your attributes."
          />
          <div className="flex flex-row justify-end items-center gap-4 px-4">
            <div className="text-sm">Skill Points Remaining</div>
            <div className="text-sm">
              {MAX_SKILL - skillPointsUsed} / {MAX_SKILL}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-300 py-2 px-4 mt-4 flex flex-row justify-end items-center">
        <Button
          label="Continue"
          onClick={() => onContinue(form)}
          icon="pi pi-arrow-right"
          size="small"
        />
      </div>
    </>
  );
};
