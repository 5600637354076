import { Button, ButtonProps } from 'primereact/button';
import { FC } from 'react';
import clsx from 'clsx';

type BottomDockProps = {
  model: ButtonProps[];
  visible: boolean;
  onHide: () => void;
};

export const BottomDock: FC<BottomDockProps> = ({ model, visible, onHide }) => {
  return (
    <div
      className={clsx(
        'absolute bottom-36 right-4 left-4 flex flex-row items-center justify-center gap-4',
        !visible && 'hidden',
      )}
    >
      {model.map(({ className, ...props }, index) => (
        <Button
          key={index}
          {...props}
          className={clsx(className, 'interactive')}
        />
      ))}
      <Button className="interactive" icon="pi pi-times" onClick={onHide} />
    </div>
  );
};
