import { FC, useCallback } from 'react';
import { ReactComponent as Throne } from '../../../../../assets/icons/menu/throne-king.svg';
import { ReactComponent as Council } from '../../../../../assets/icons/menu/council.svg';
import { ReactComponent as Military } from '../../../../../assets/icons/menu/spartan.svg';
import { ReactComponent as Capitol } from '../../../../../assets/icons/menu/capitol.svg';
import { ReactComponent as Cog } from '../../../../../assets/icons/menu/cog.svg';
import { MenuType, setMenuTab } from '../../../../store/menu-store';

export const RightDockOnMap = () => {
  const handleMenuClick = useCallback(
    (item: MenuType) => () => setMenuTab(item),
    [],
  );
  return (
    <div className="absolute right-0 bottom-4 top-32">
      <div className="flex flex-col items-center justify-center gap-10 rounded-l-3xl px-2 py-4 bg-opacity-50 bg-gray-700 md:shadow-gray-700/10 md:shadow-lg">
        <Btn
          icon={<Throne />}
          label="Realm"
          onClick={handleMenuClick(MenuType.Realm)}
        />
        <Btn
          icon={<Council />}
          label="Council"
          onClick={handleMenuClick(MenuType.Council)}
        />
        <Btn
          icon={<Military />}
          label="Military"
          onClick={handleMenuClick(MenuType.Military)}
        />
        <Btn
          icon={<Capitol />}
          label="Something"
          onClick={handleMenuClick(MenuType.None)}
        />
        <Btn
          icon={<Cog />}
          label="Settings"
          onClick={handleMenuClick(MenuType.Settings)}
        />
      </div>
    </div>
  );
};

type BtnProps = {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
};

const Btn: FC<BtnProps> = ({ icon, label, onClick }) => {
  return (
    <button
      className="interactive text-orange-300 hover:scale-125 transition duration-300 flex flex-col items-center justify-center"
      onClick={onClick}
    >
      <div className="w-8 h-8 rounded-full bg-black bg-opacity-75">
        <div className="w-8 scale-150">{icon}</div>
      </div>
      <div className="text-white mt-3 text-center text-[0.5rem]">{label}</div>
    </button>
  );
};
