import { useSelectedHolding } from '../../../../scenes/CityScene/hooks/useSelectedHolding';
import { CoatOfArms } from '../../../1_atoms/CoatOfArms/CoatOfArms';

export const CityUiHeader = () => {
  const holding = useSelectedHolding();
  if (!holding) return <></>;
  return (
    <div className="absolute top-8 right-2">
      <div className="flex flex-row justify-start items-start">
        <div>
          <div className="text-xs bg-orange-200 px-2 rounded-bl">
            {holding.name}
          </div>
        </div>
        <CoatOfArms coa={holding.title.coa} className="w-12" />
      </div>
    </div>
  );
};
