import { FC } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ModalProps } from '../../types';
import { useSettingsStore } from '../../../../../store/settings/settings-store';

export const SettingsDialog: FC<ModalProps> = ({ visible, onClose }) => {
  const settings = useSettingsStore(state => state);

  return (
    <Dialog
      header="Settings"
      visible={visible}
      onHide={onClose}
      className="w-full max-w-md"
    >
      <h4 className="mb-2">Assets</h4>
      <div className="flex align-items-center mb-3">
        <Checkbox
          inputId="trees"
          name="trees"
          value="true"
          onChange={() =>
            settings.setDisableMapAssets(
              'trees',
              !settings.disableMapAssets.trees,
            )
          }
          checked={settings.disableMapAssets.trees}
        />
        <label htmlFor="trees" className="ml-2">
          Disable trees
        </label>
      </div>

      <div className="flex align-items-center mb-3">
        <Checkbox
          inputId="rocks"
          name="rocks"
          value="true"
          onChange={() =>
            settings.setDisableMapAssets(
              'rocks',
              !settings.disableMapAssets.rocks,
            )
          }
          checked={settings.disableMapAssets.rocks}
        />
        <label htmlFor="rocks" className="ml-2">
          Disable rocks
        </label>
      </div>

      <div className="flex align-items-center mb-3">
        <Checkbox
          inputId="grass"
          name="grass"
          value="true"
          onChange={() =>
            settings.setDisableMapAssets(
              'grass',
              !settings.disableMapAssets.grass,
            )
          }
          checked={settings.disableMapAssets.grass}
        />
        <label htmlFor="grass" className="ml-2">
          Disable grass
        </label>
      </div>

      <hr className="my-2" />

      <div className="flex align-items-center mb-3">
        <Checkbox
          inputId="devControls"
          name="devControls"
          value="true"
          onChange={() => settings.setDevControls(!settings.devControls)}
          checked={settings.devControls}
        />
        <label htmlFor="devControls" className="ml-2">
          Dev Controls
        </label>
      </div>
      <div className="flex align-items-center mb-3">
        <Checkbox
          inputId="devStats"
          name="devStats"
          value="true"
          onChange={() => settings.setDevStats(!settings.devStats)}
          checked={settings.devStats}
        />
        <label htmlFor="devStats" className="ml-2">
          Dev FPS Stats
        </label>
      </div>
    </Dialog>
  );
};
