import { StructureType } from '@chiefdom/collections';

export type IPlayer = {
  id: number;
  loginId: string;
  name: string;
  coa: ICoa;
  characterId: number;
  realmId: number;
  dynastyId: number;
  realm: IRealm;
  dynasty: IDynasty;
  character: ICharacter;
};

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export type ICharacter = {
  id: number;
  name: string;
  sex: Sex;
  lifeStage: string;
  dynasty: IDynasty;
  holdings: IHolding[];
  titles: ITitle[];
};

export type IDynasty = {
  id: number;
  name: string;
  coa: ICoa;
};

export type ICoa = {
  shape: string;
  primaryColor: string;
  secondaryColor: string;
};

export type IRealm = {
  id: number;
  title: ITitle;
  capital: IHolding;
  holdings: IHolding[];
  realmHoldings: IHolding[]; // todo: BE should return this
};

export enum TitleRank {
  BARONY = 'BARONY',
  DUCHY = 'DUCHY',
  COUNTY = 'COUNTY',
  KINGDOM = 'KINGDOM',
  EMPIRE = 'EMPIRE',
}

export type ITitle = {
  id: number;
  coa: ICoa;
  name: string;
  shortName: string;
  ruler: ICharacter;
  rulerId: number;
  rank: TitleRank;
};

/** @deprecated use HoldingType from collectibles package */
export enum HoldingType {
  TRIBE = 'TRIBE',
  CASTLE = 'CASTLE',
  CITY = 'CITY',
  TEMPLE = 'TEMPLE',
}

export type IHolding = {
  id: number;
  name: string;
  shortName: string;
  title: ITitle;
  titleId: number;
  realm: IRealm;
  realmId: number;
  type: HoldingType;
  level: number;
  q: number;
  r: number;
  s: number;
  structures: IStructure[];
  ruler: ICharacter;
  rulerId: number;
};

export type IStructure = {
  id: number;
  type: StructureType;
  level: number;
  q: number;
  r: number;
  s: number;
  w: number;
};
