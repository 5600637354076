import { FC, PropsWithChildren, useEffect } from 'react';

import { onAuthStateChanged } from 'firebase/auth';

import { LoginOnboardingPage } from '../../6_pages/LoginOnboardingPage/LoginOnboardingPage';
import { auth } from '../../../../config/firebase';
import { useAuthStore } from '../../../store/auth-store';
import { useShardStore } from '../../../store/shard-store';

export const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
  const isLoading = useAuthStore(state => state.firebase.loading);
  const setLoading = useAuthStore(state => state.firebase.setLoading);
  const setUser = useAuthStore(state => state.firebase.setUser);
  const setIdToken = useAuthStore(state => state.firebase.setIdToken);

  useEffect(() => {
    setLoading(true);
    onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
        auth.currentUser?.getIdToken().then(idToken => {
          setLoading(false);
          setIdToken(idToken);
        });
      } else {
        setUser(null);
        setIdToken(null);
        setLoading(false);
      }
    });
  }, [setIdToken, setLoading, setUser]);

  if (isLoading) return <>loading...</>;

  return <AuthOnboarding>{children}</AuthOnboarding>;
};

const AuthOnboarding: FC<PropsWithChildren> = ({ children }) => {
  const idToken = useAuthStore(state => state.firebase.idToken);
  const shard = useShardStore(state => state.shard);

  if (idToken && shard) return <>{children}</>;

  return <LoginOnboardingPage hasAccount={!!idToken} hasShard={!!shard} />;
};
