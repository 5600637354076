import { FC, useCallback, useState } from 'react';
import { useGameStore } from '../../../store/game-store';
import { gs } from '../../../../utils/clients/gs';
import { randomHexColor } from '../../../../utils/colors';
import {
  CharacterCreatorFormState,
  CharacterCreator,
} from './components/CharacterCreator';
import { CoatOfArmsCreator } from './components/CoatOfArmsCreator';
import { Sex } from '../../../../types/models';
import { CoaFormState } from '../../1_atoms/CoaEditor/CoaEditor';
import { getRandomDynastyName, getRandomMaleName } from '@chiefdom/shared';
import { ScrollableArea } from '../../1_atoms/ScrollabelArea/ScrollableArea';

enum Step {
  CHARACTER_CREATOR,
  COAT_OF_ARMS,
}

export const GameOnboardingPage: FC = () => {
  const updatePlayer = useGameStore(state => state.updatePlayer);
  const [, setLoading] = useState(false);

  const [step, setStep] = useState(Step.CHARACTER_CREATOR);
  const [characterForm, setCharacterForm] = useState<CharacterCreatorFormState>(
    {
      firstName: getRandomMaleName(),
      houseName: getRandomDynastyName(),
      sex: Sex.MALE,
      diplomacy: 5,
      martial: 5,
      stewardship: 5,
      intrigue: 5,
      learning: 5,
    },
  );
  const [coatOfArmsForm, setCoatOfArmsForm] = useState<CoaFormState>({
    shape: 'cross',
    primaryColor: randomHexColor(),
    secondaryColor: randomHexColor(),
  });

  const handleLogin = useCallback(
    (coa: CoaFormState) => {
      setLoading(true);

      gs.post('/player', {
        ...characterForm,
        coatOfArms: coa,
      })
        .then(() => {
          updatePlayer();
        })
        .catch(error => {
          setLoading(false);
          console.error(error);
        });
    },
    [characterForm, updatePlayer],
  );

  const handleContinue = useCallback((char: CharacterCreatorFormState) => {
    setCharacterForm(char);
    setStep(Step.COAT_OF_ARMS);
  }, []);

  const submitCharacter = useCallback(
    (coa: CoaFormState) => {
      setCoatOfArmsForm(coa);
      handleLogin(coa);
    },
    [handleLogin],
  );

  const handleBack = useCallback(() => {
    setStep(Step.CHARACTER_CREATOR);
  }, []);

  return (
    <ScrollableArea>
      <main className="w-full min-h-full flex flex-col items-center justify-center p-4 md:p-8">
        <div className="container max-w-xl">
          {step === Step.CHARACTER_CREATOR && (
            <CharacterCreator
              value={characterForm}
              onContinue={handleContinue}
            />
          )}
          {step === Step.COAT_OF_ARMS && (
            <CoatOfArmsCreator
              value={coatOfArmsForm}
              onSubmit={submitCharacter}
              onCancel={handleBack}
            />
          )}
        </div>
      </main>
    </ScrollableArea>
  );
};
